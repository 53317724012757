<template>
  <div
    :class="[
      'title-iso',
      { 'title-iso--negative': reverse },
      { 'title-iso--underline': underline },
    ]"
  >
    <component
      :is="tagName"
      :class="[
        'title-iso__content',
        'typo-h1-iso',
      ]"
    >
      {{ content }}
    </component>
  </div>
</template>

<script>
import UiTitle from '~/components/ui/Title';

export default {
  mixins: [UiTitle],

  props: {
    reverse: { type: Boolean, default: false },
    underline: { type: Boolean, default: true },
  },
};
</script>

<style lang="stylus" scoped>
$breakpointIso = XL

addLine($color) {
  $line-scale = 1.5
  position relative

  &:after {
    display block
    width 100%
    height 5px
    background-color $color
    margin-top 10px

    border-top-left-radius (7px / $line-scale)
    transform scaleX($line-scale)
    transform-origin left top

    .title-iso--underline & {
      content: ''
    }
  }
}

addIsoShapes($color1, $color2) {
  $title-angle = 33deg
  $title-angle-opposite = 50deg
  $title-offset = 81%

  position relative
  z-index -2

  text-align right
  background-color $color1
  transform-origin right bottom
  transform translate(-($title-offset * 0.9), 0) skewY($title-angle)

  +size(XXL) {
    transform translate(-($title-offset), 0) skewY($title-angle)
  }

  &:after {
    content ''
    position absolute
    top 0
    left 100%
    bottom auto

    width 100%
    height 100%

    margin 0

    background-color $color2
    transform skewY(-($title-angle-opposite))
    transform-origin left top
  }

  .title-iso--negative & {
    transform translate(-($title-offset * 0.9), 0) skewY(-($title-angle))

    +size(XXL) {
      transform translate(-($title-offset), 0) skewY(-($title-angle))
    }
    &:after {
      transform skewY($title-angle-opposite)
    }
  }
}

.title-iso {
  margin-top 100px

  +size($breakpointIso) {
    margin-top 250px
    margin-bottom 3em

    + :deep(.richtext),
    + :deep(h1),
    + :deep(h2),
    + :deep(h3),
    + :deep(p) {
      margin-top -230px;
    }
  }
}

.title-iso--negative {
  +size($breakpointIso) {
    margin-top 3em
    margin-bottom 12em
  }
}

.title-iso__content {
  display block
  addLine(var(--theme-color, red))

  +size($breakpointIso) {
    padding 2em 0.5em 1em 0
    addIsoShapes(var(--theme-color, red), $cl-flat)
  }
}
</style>
