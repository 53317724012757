<template>
  <div class="grid-layout">
    <div class="grid-layout__items">
      <div
        v-for="(itemPos, itemIndex) in getPositions(collection.length)"
        :key="`grid-item-${itemIndex}`"
        :class="[
          'grid-layout__item',
          pattern ? `grid-layout__item--pattern-${itemPos.patternIndex}` : '',
          pattern ? `grid-layout__item--index-${itemPos.patternPosition}` : '',
        ]"
      >
        <slot
          name="item"
          :item="collection[itemIndex]"
          :position="itemPos"
          :index="itemIndex"
        >
          <span>Here the grid item #{{ itemIndex }} content</span>
        </slot>
      </div>

      <div
        v-for="(emptyPos, emptyIndex) in getPositions(emptyItemCount, collection.length)"
        :key="`grid-empty-item-${emptyIndex + collection.length}`"
        :class="[
          'grid-layout__item',
          pattern ? `grid-layout__item--pattern-${emptyPos.patternIndex}` : '',
          pattern ? `grid-layout__item--index-${emptyPos.patternPosition}` : '',
          'grid-layout__item--empty',
        ]"
      />
    </div>
  </div>
</template>

<script>
function arraySumN(list, n = 0) {
  return list.slice(0, n).reduce((a, b) => a + b, 0);
}

export default {
  props: {
    collection: { type: Array, default: () => [] },
    patternItemCount: { type: Number, default: 0 },
    patternFillCount: { type: [Number, Array], default: 0 },
    rowPattern: { type: [Number, Array], default: 0 },
  },

  computed: {
    pattern() {
      if (Array.isArray(this.rowPattern)) return this.rowPattern.length ? this.rowPattern : null;
      return this.rowPattern > 0 ? [this.rowPattern] : null;
    },

    emptyItemCount() {
      if (!this.pattern) return;
      if (!this.collection || this.collection.length <= 0) return;

      const lastItemPosition = this.getPatternPosition(this.collection.length - 1);
      return this.pattern[lastItemPosition.patternIndex] - lastItemPosition.patternPosition - 1;
    },
  },

  methods: {
    getPatternPosition(index) {
      if (!this.pattern) return null;

      const pattern = Array.isArray(this.rowPattern) ? this.rowPattern : [this.rowPattern];
      const total = pattern.reduce((prev, current) => prev + current);
      const repeatCount = Math.floor(index / total); // complete pattern repeat count
      const repeatPosition = index - repeatCount * total;
      let patternIndex = 0;
      let patternPosition = 0;

      while (repeatPosition >= arraySumN(pattern, patternIndex) && patternIndex < pattern.length) {
        ++patternIndex;
      }
      patternIndex = Math.max(patternIndex - 1, 0);
      patternPosition = repeatPosition - arraySumN(pattern, patternIndex);

      return ({ repeatCount, repeatPosition, patternIndex, patternPosition });
    },

    getPositions(length, start = 0) {
      return Array.from({ length }, (_, index) => this.getPatternPosition(index + start));
    },
  },
};
</script>

<style lang="stylus" scoped>
$collection-layout-breakpoint := 780px

.grid-layout__items {
  @media (min-width: $collection-layout-breakpoint) {
    display flex
    flex-wrap wrap
  }
}

.grid-layout__item {
  width 100%
  @media (min-width: $collection-layout-breakpoint) {
    width (100 / 3)%
  }
}

.grid-layout__item--empty {
  display none
  @media (min-width: $collection-layout-breakpoint) {
    display block
  }
}
</style>