/* eslint-disable no-undef */
import { Marked } from 'marked';
import { mangle } from 'marked-mangle';

const marked = new Marked({ headerIds: false });
marked.use(mangle());

export default defineNuxtPlugin(() => {
  const pathJoin = (...parts) => parts.join('/').replace(/\/{1,}/g, '/').replace(/^(.*):\/*/, '$1://');
  const isAbsolutePath = (path) => !!path.match(/^[a-z,0-9]*:\/\//i);

  return {
    provide: {
      mediaUrl: (path) => {
        if (!path) return null;

        if (!isAbsolutePath(path)) {
          const runtimeConfig = useRuntimeConfig();
          const basePath = runtimeConfig.public.mediasPath || '';
          return pathJoin(basePath, path);
        }

        return path;
      },
      md2Html: (md)=> {
        return marked.parse(md);
      },
    },
  };
});