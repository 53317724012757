<template>
  <component
    :is="componentName"
    :class="[
      'button-flat',
      { 'button-flat--nolabel': !hasLabel },
      { 'button-flat--small': small },
    ]"
    v-bind="$props"
  >
    <div class="button-flat__picto picto">
      <UiPicto :type="type" class="picto__shape" />
    </div>

    <span class="button-flat__label typo-link-bold">
      <slot>{{ alt }}</slot>
    </span>
  </component>
</template>

<script>
export default {
  props: {
    alt: { type: String, default: null },
    type: { type: String, default: null },
    small: { type: Boolean, default: false },
  },

  computed: {
    componentName() {
      return this.$props.to !== null ? resolveComponent('NuxtLink') : 'button';
    },

    hasLabel() {
      return (Object.keys(this.$slots)).includes('default');
    },
  },
};
</script>

<style lang="stylus" scoped>
$button-flat-label-size ?= 15px
$button-flat-width ?= 50px
$button-flat-height ?= 60px
$button-flat-width-small ?= 36px
$button-flat-height-small ?= 40px
$button-flat-gap ?= 25px

.button-flat {
  @extends $reset-button !optional

  display flex
  align-items center

  text-decoration none
  cursor pointer
}

.button-flat__picto {
  position relative

  width s('var(--button-flat-width, %s)', $button-flat-width)
  height s('var(--button-flat-height, %s)', $button-flat-height)

  background-color var(--theme-color, black)
  margin-right s('var(--button-flat-gap, %s)', $button-flat-gap)

  mask-image url('/medias/shape-flat-button.svg')
  mask-size 100%
  mask-repeat no-repeat
  mask-position center

  transition transform 0.1s ease-in, background-color 0.2s ease-in

  .button-flat--nolabel & {
    margin-right 0
  }

  .button-flat--small & {
    width s('var(--button-flat-width-small, %s)', $button-flat-width-small)
    height s('var(--button-flat-height-small, %s)', $button-flat-height-small)
  }

  .button-flat:hover & {
    transform scale(0.9)
    transition transform 0.1s ease-out
  }
}

.button-flat__label {
  display inline-block

  font-size s('var(--button-flat-label-size, %s)', $button-flat-label-size)
  text-transform uppercase
  color var(--theme-color-text, black)

  .button-flat--nolabel & {
    width 0
    height 0
    overflow hidden
  }
}

.picto__shape {
  position absolute
  left 50%
  top 50%

  display block
  width 100%
  height 100%

  color var(--theme-color-text, black)
  transform translate(-50%, -50%)
}
</style>
