<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
useHead({
  htmlAttrs:[
    {"lang": "fr"},
  ],
});

const nuxtApp = useNuxtApp();
nuxtApp.hook('vue:error', (err) => {
    console.error("Catched Error : ");
    console.error(JSON.stringify(err, null, 2));
});
</script>

<style lang="stylus">
@import 'assets/styles/global.styl'
</style>
