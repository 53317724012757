<template>
  <div class="featured-card">
    <UiCardProject
      :button-label="cardButton"
      :image="cardImage"
      :name="cardName"
      :href="href"
      class="featured-card__card"
    />

    <div class="featured-card__text">
      <p class="featured-card__title typo-featured-card">
        {{ textTitle }}
      </p>

      <p>
        {{ textDescription }}
      </p>

      <UiButtonFlat type="right" :href="href">
        {{ textButton }}
      </UiButtonFlat>
    </div>
  </div>
</template>

<script>
import { Media } from '~/data/media.js';
export default {
  props: {
    cardButton: { type: String, default: null },
    cardImage: { type: Media, default: null },
    cardName: { type: String, default: null },
    href: { type: String, default: null },
    textTitle: { type: String, default: null },
    textDescription: { type: String, default: null },
    textButton: { type: String, default: null },
  },
};
</script>

<style lang="stylus" scoped>
$collection-layout-breakpoint := L

.featured-card {
  transition background-color 0.25s

  +size($collection-layout-breakpoint) {
    display flex
  }
}

.featured-card:hover {
  background-color $cl-flat
}

.featured-card__text
.featured-card__card {
  box-sizing border-box

  +size($collection-layout-breakpoint) {
    width 50%
    height 100%
    padding 75px 126px
  }
}

.featured-card__text {
  padding 35px 15px 50px 15px
}

.featured-card__card {
  padding 50px 60px

  border-top 1px solid white
  background-color var(--theme-color, white)

  :deep(.card-layout__link) {
    width 100vw
  }
}

.featured-card__text
.featured-card__card {
  +size($collection-layout-breakpoint) {
    padding 75px
  }

  +size(XL) {
    padding 75px 126px
  }
}

.featured-card__text {
  border-top 1px solid $cl-separator
}

.featured-card__title {
  font-weight bold
  margin 0
}
</style>
