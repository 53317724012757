<template>
  <LayoutCard
    :href="href"
    :class="[
      'edito-card',
      { 'edito-card--link': !!href },
    ]"
  >
    <template #head>
      <UiMediaImage
        :image="image"
        :masked="true"
        :fit="true"
        :width="small ? 610 : 960"
        ratio="1"
        class="edito-card__image"
      />
    </template>

    <template v-if="buttonLabel" #overlay>
      <UiTag :label="buttonLabel" />
    </template>

    <template #body>
      <p class="edito-card__title typo-h3">
        {{ name }}
      </p>
    </template>
  </LayoutCard>
</template>

<script>
import { Media } from '~/data/media.js';

export default {
  props: {
    buttonLabel: { type: String, default: null },
    href: { type: String, default: null },
    image: { type: Media, default: null },
    name: { type: String, default: null },
    small: { type: Boolean, default: false },
  },
};
</script>

<style lang="stylus" scoped>
card-mask-animation('.edito-card', '.edito-card__image', '.edito-card__title', true, false)

.edito-card__image {
  aspect-ratio 1/1
}

.edito-card__title {
  margin-top 25px
}

:deep(.card-layout__overlay) {
  bottom 20px
  left 20px
}
</style>
