<template>
  <LayoutCard
    :href="href"
    :class="[
      'project-card',
      { 'project-card--link': !!href },
    ]"
  >
    <template #head>
      <UiMediaImage
        :image="image"
        :masked="true"
        :fit="true"
        :ratio="16/9"
        :quality="40"
        width="910"
        class="project-card__image"
      />
    </template>

    <template v-if="buttonLabel" #overlay>
      <UiTag :label="buttonLabel" />
    </template>

    <template #body>
      <p class="project-card__title typo-h3">
        {{ name }}
      </p>
    </template>
  </LayoutCard>
</template>

<script>
import { Media } from '~/data/media.js';
export default {
  props: {
    buttonLabel: { type: String, default: null },
    href: { type: String, default: null },
    image: { type: Media, default: null },
    name: { type: String, default: null },
  },
};
</script>

<style lang="stylus" scoped>
card-color-animation('.project-card', '.project-card__title', '.project-card__image')

.project-card__image {
  aspect-ratio 16/9
}

.project-card__title {
  margin-top 25px
}

:deep(.card-layout__overlay) {
  bottom 20px
  left 20px
}
</style>
