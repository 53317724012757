<template>
  <div>
    <audio ref="player" :src="path" />

    <UiTimeline :current="current" :total="total" @seek="seeking" />
  </div>
</template>

<script>
export default {
  props: {
    path: { type: String, default: null },
  },

  emits: ['start', 'end'],

  data() {
    return ({
      play: false,
      current: 0,
      total: 0,
      isEnded: true,
    });
  },

  watch: {
    play(value) {
      if (!this.$refs.player) return;
      if (value) {
        this.isEnded = false;
        this.$refs.player.play();
      } else {
        this.$refs.player.pause();
      }
    },

    isEnded(value) {
      this.$emit(value ? 'end' : 'start');
    },
  },

  mounted() {
    this.mountPlayer();
  },

  beforeUnmount() {
    this.unmountPlayer();
  },

  methods: {
    mountPlayer() {
      /*
      const player = this.$el.querySelector('audio');
      if (player && !this.$refs.player) {
        console.log('retry get player');
        this.$nextTick(this.mountPlayer);
        return;
      }
      */

      this.$refs.player.addEventListener('durationchange', this.updateDuration);
      this.$refs.player.addEventListener('timeupdate', this.updateCurrentTime);
      this.$refs.player.addEventListener('ended', this.onEnd);

      this.updateDuration();
      this.updateCurrentTime();
    },

    unmountPlayer() {
      if (!this.$refs.player) return;
      this.$refs.player.removeEventListener('durationchange', this.updateDuration);
      this.$refs.player.removeEventListener('timeupdate', this.updateCurrentTime);
      this.$refs.player.removeEventListener('ended', this.onEnd);
    },

    updateDuration() {
      this.total = this.$refs.player.duration;
    },

    updateCurrentTime() {
      this.current = this.$refs.player.currentTime;
    },

    onEnd() {
      this.play = false;
      this.isEnded = true;
    },

    toggle() {
      this.play = !this.play;
    },

    seeking(value) {
      this.$refs.player.currentTime = value;
    },
  },
};
</script>
