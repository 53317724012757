
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "a84f57c8-efcf-4ded-8baa-c2fe8e3e4fa1"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/opt/atlassian/pipelines/agent/build/app.config.js"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
