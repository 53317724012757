<template>
  <UiTitleIso
    :level="level"
    :content="name"
    :reverse="false"
  />
</template>

<script>
export default {
  props: {
    name: { type: String, default: '' },
    level: { type: Number, default: 2 },
  },
};
</script>