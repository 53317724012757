//
// #data object definitions
//
export class RichText {
  constructor({ content }) {
    this.content = content;
  }
}
//
// #data object factories
//
export function getRichTextsFromData(apiData) {
  const data = apiData?.attributes;
  if (!data) { return null }

  return new RichText(data);
}