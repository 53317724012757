<template>
  <div class="filters">
    <UiTag
      v-model="defaultFilter.checked"
      :label="defaultFilter.label"
      :toggle="true"
      :class="[
        'filters__filter',
        { 'filters__filter--disabled': defaultFilter.checked },
      ]"
      :disabled="defaultFilter.checked"
    />

    <UiTag
      v-for="(filter, filterIndex) in modelValue"
      :key="`grid-filter-${filterIndex}`"
      v-model="filter.checked"
      :label="filter.label"
      :toggle="true"
      class="filters__filter"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: { type: Array, default: null },
  },

  data() {
    return {
      defaultFilter: {
        label: 'Tous',
        checked: true,
      },
    };
  },

  computed: {
    hasFilterEnabled() {
      return this.modelValue.findIndex(value => value.checked) > -1;
    },
  },

  watch: {
    hasFilterEnabled: {
      handler(value) {
        this.defaultFilter.checked = !value;
      },
      immediate: true,
    },

    'defaultFilter.checked'(value) {
      if (value === true) {
        this.modelValue.forEach(filter => filter.checked = false);
      }
    },
  },

  methods: {
    toggleDefaultFilter(e) {
      if (this.defaultFilter.checked) {
        e.stopImmediatePropagation();
        e.preventDefault();
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.filters {
  display flex
  flex-wrap wrap
  gap 16px
}

.filters__filter::first-letter {
  text-transform uppercase
}

.filters__filter--disabled {
  pointer-events none
}
</style>
