<template>
  <div class="filters-combo">
    <div class="filters-combo__wrapper">
      <p class="filters-combo__label typo-h2">
        Filtrer
      </p>

      <div :class="['filter-combo__switch', { 'filter-combo__switch--open': open }]">
        <UiTag
          :label="label"
          :model-value="true"
          :style="{ 'pointer-events': open ? 'none' : 'auto' }"
          class="filters-combo__combo-label"
          @click="open = true"
        />
      </div>

      <div v-show="open" class="filters-combo__list" @mouseup.capture.prevent.stop="">
        <UiTag
          v-for="(filter, index) in modelValue"
          :key="`filter-${index}`"
          v-model="filter.checked"
          :label="filter.label"
          :toggle="true"
          class="filters-combo__filter"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: { type: Array, default: null },
  },

  data() {
    return {
      open: false,
    };
  },

  computed: {
    label() {
      const defaultLabel = 'Tous';
      const selectedCount = this.enabledFilters?.length || 0;
      const filterCount = this.modelValue?.length || 0;

      if (selectedCount === 0 || selectedCount === filterCount) {
        return defaultLabel;
      }

      if (this.enabledFilters?.length > 1) {
        return `${selectedCount} filtres`;
      }

      return this.enabledFilters[0].label;
    },

    enabledFilters() {
      if (!this.modelValue?.length) return null;
      return this.modelValue.filter(value => value.checked);
    },
  },

  mounted() {
    window.addEventListener('mouseup', this.close);
  },

  beforeUnmount() {
    window.removeEventListener('mouseup', this.close);
  },

  methods: {
    close() {
      this.open = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.filters-combo__wrapper {
  position relative
  display inline-block
}

.filters-combo__list {
  position absolute
  top 100%
  right 0
  z-index 1

  display flex
  justify-content flex-end
  flex-wrap wrap
  gap 15px

  box-sizing border-box
  padding 15px
  max-width 90vw

  background-color white
  border 1px solid $cl-separator
  border-bottom-left-radius 7px
}

.filters-combo__label {
  display inline-block
  margin 0 15px 0 0
}

.filter-combo__switch {
  display inline-block
  &:after {
    position absolute
    top 50%
    right 10px
    z-index 1

    content ''
    width 7px
    height @width
    display block
    box-sizing border-box
    border-top 1px solid white
    border-right @border-top

    transform translateY(-50%) rotate(135deg)
  }
}

.filter-combo__switch--open {
  &:after {
    transform translateY(0) rotate(-45deg)
  }
}

.filters-combo__combo-label {
  position relative
  padding-right 26px
}

.filters-combo__filter:hover {
  background-color var(--theme-color, black)
  border-top-left-radius 7px
}
</style>
