<template>
  <div
    :class="[
      'service-card',
      { 'service-card--link': !!href },
    ]"
  >
    <p class="service-card__title typo-title-list-item">
      {{ title }}
    </p>

    <UiButtonFlat type="right" class="service-card__button" />

    <UiOverlayLink label="voir" :href="href" />
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: null },
    href: { type: String, default: null },
  },
};
</script>

<style lang="stylus" scoped>

.service-card {
  position relative
  display flex
  justify-content space-between
  padding 25px 15px

  +size(L) {
    padding 50px
  }
}

.service-card__button {
  --theme-color white
}

.service-card--link {
  &:hover {
    background-color $cl-flat
    .service-card__title {
      text-decoration underline
    }

    .service-card__button {
      --theme-color inherit
    }
  }
}
</style>
