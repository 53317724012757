<template>
  <CollectionFeatured :collection="collection" class="collection" />
</template>

<script>
import { getFeaturedFromData } from '~/data/featured';

export default {
  props: {
    items: { type: Array, default: null },
  },

  computed: {
    collection() {
      if (!this.items?.length) return null;
      return this.items.map(item => getFeaturedFromData(item));
    },
  },
};
</script>
