<template>
  <div class="header-centered">
    <h1 class="header-centered__title typo-h1-small">
      {{ title }}
    </h1>

    <p class="header-centered__subtitle typo-header-italic">
      {{ subtitle }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
  },
};
</script>

<style lang="stylus" scoped>
.header-centered {
  text-align center
}

.header-centered__subtitle {
  max-width 833px
  margin-left auto
  margin-right @margin-left
}
</style>
