<template>
  <div class="marquee">
    <div class="marquee__wrapper typo-marquee">
      <template v-for="(item, index) in [...items, ...items]" :key="`marquee-${index}-${item.Keyfigure}`">
        <span class="typo-marquee-fat">&nbsp;•&nbsp;{{ item.Keyfigure }}&nbsp;</span>
        {{ item.label }}
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array, default: null },
  },
};
</script>

<style lang="stylus" scoped>
.marquee {
  background-color var(--theme-color, black)
  color black;
  width 100%
  overflow hidden
}

.marquee__wrapper {
  display inline-block
  white-space nowrap
  padding 75px 0

  text-wrap nowrap
  transform-origin left center
  animation marquee 25s linear infinite
  user-select none
  pointer-event none
}

.marquee__item {
  display inline-block
}

@keyframes marquee {
  from {
    transform translateX(0%)
  }
  to {
    transform translateX(-50%)
  }
}

</style>
