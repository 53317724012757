<template>
  <video
    v-bind="options"
    controls
    crossorigin
    playsinline
    :poster="poster"
  >
    <source :src="video">
  </video>
</template>

<script>
export default {
  props: {
    video: { type: String, default: null },
    poster: { type: String, default: null },
    options: { type: Object, default: () => ({}) },
  },
};
</script>
