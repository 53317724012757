<template>
  <div v-if="groups && groups.length">
    <div
      v-for="(group, groupIndex) in groups"
      :key="`group-${groupIndex}`"
      class="list__group"
    >
      <div class="list__label">
        <p class="list__label-text">
          {{ group.label }}
        </p>
      </div>

      <div class="list__items">
        <div
          v-for="(item, index) in group.list"
          :key="`list-item-${index}`"
          class="list__item"
        >
          <slot name="item" :item="item">
            <span>Here the list item #{{ itemIndex }} content</span>
          </slot>
        </div>
      </div>
    </div>
  </div>

  <div v-else-if="collection && collection.length">
    <div
      v-for="(item, index) in collection"
      :key="`list-item-${index}`"
    >
      <slot name="item" :item="item">
        <span>Here the list item #{{ itemIndex }} content</span>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    collection: { type: Array, default: () => [] },
    groupBy: { type: Function, default: null },
    groupSort: { type: Function, default: null },
  },

  computed: {
    groups() {
      if (!this.groupBy) return null;

      const itemsByGroup = {
        lists: {},
        push(groupLabel, item) {
          if (!this.lists[groupLabel]) {
            this.lists[groupLabel] = [item];
          } else {
            this.lists[groupLabel].push(item);
          }
        },
      };

      this.collection.forEach(item => { itemsByGroup.push(this.groupBy(item), item) });

      const groups = Object.entries(itemsByGroup.lists).map(([label, list]) => ({ label, list }));
      if (!this.groupSort) return groups;

      return groups.sort(this.groupSort);
    },
  },
};
</script>

<style lang="stylus" scoped>
$list-layout-breakpoint := 780px

.list__group {
  +size($list-layout-breakpoint) {
    display flex
  }
}

.list__label {
  position sticky
  top 0
  z-index 1
  

  width 100%
  flex-shrink 0
  background url("~/assets/images/hexagrid.svg") repeat-y, var(--theme-color, white)
  +size($list-layout-breakpoint) {
    position static
    width 504px
  }
}

.list__label-text {
  +size($list-layout-breakpoint) {
    position sticky
    top 0
    z-index 1
    width 504px
  }
}

.list__items {
  flex-grow 1
}
</style>
