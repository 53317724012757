<template>
  <LayoutList
    v-bind="$props"
    class="theme-prob"
  >
    <template #item="{ item }">
      <UiCardFeatured
        v-if="item?.project"
        :card-button="item.project?.client?.name"
        :card-image="item.project?.cover"
        :card-name="item.project?.name"
        :href="item.project?.url"
        :text-title="item.title"
        :text-description="item.description"
        :text-button="item.buttonLabel"
      />
      <div v-else class="app-width-small" style="padding: 40px">
        <span class="typo-featured-card">{{ item.title }}</span><br>
        {{ item.description }}
      </div>
    </template>
  </LayoutList>
</template>
