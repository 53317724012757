<template>
  <UiTitleIso
    v-if="level <= 2"
    :level="level"
    :content="content"
    :reverse="level <= 1"
  />
  <UiTitleArrow
    v-else
    :level="level"
    :content="content"
    :has-arrow="hasArrow"
  />
</template>

<script>
export default {
  props: {
    level: { type: [Number, String], default: 1 },
    content: { type: String, default: '' },
    hasArrow: { type: Boolean, default: true },
  },
};
</script>
