<template>
  <LayoutCollection :row-pattern="[3, 2]" v-bind="$props" class="theme-prob">
    <template #item="{ item }">
      <UiCardProject
        :button-label="item.client?.name"
        :href="item.url"
        :image="item.cover"
        :name="item.name"
      />
    </template>
  </LayoutCollection>
</template>

<style lang="stylus" scoped>
$collection-layout-breakpoint := L

:deep(.grid-layout__filters) {
  margin 50px 0

  +size($collection-layout-breakpoint) {
    margin 40px 0
  }
}

:deep(.grid-layout__items) {
  border 0.5px solid $cl-separator
}

:deep(.grid-layout__item) {
  box-sizing border-box
  border 0.5px solid $cl-separator

  &:nth-child(odd) {
    background-color var(--theme-color, white)
    +size($collection-layout-breakpoint) {
      background-color transparent
    }
  }
}

:deep(.grid-layout__item--pattern-1) {
  +size($collection-layout-breakpoint) {
    width 50%
  }
}

:deep(.project-card) {
  width 100%
  height 100%
  box-sizing border-box
  padding 50px 60px

  +size($collection-layout-breakpoint) {
    padding 75px 50px
  }
}
</style>
