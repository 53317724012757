
import BlockFactory from '~/plugins/utils/BlockFactory.js';
/* block button */
import Download from '~/components/blocks/button/Download';
/* block data */
import DataFeatured from '~/components/blocks/data/DataFeatured';
import DataHomeList from '~/components/blocks/data/DataHomeList';
import DataList from '~/components/blocks/data/DataList';
/* block headers */
import HeaderCentered from '~/components/blocks/headers/HeaderCentered';
import HeaderEdito from '~/components/blocks/headers/HeaderEdito';
import HeaderHero from '~/components/blocks/headers/HeaderHero';
import HeaderIso from '~/components/blocks/headers/HeaderIso';
/* block media */
import Gallery from '~/components/blocks/media/Gallery';
import SmallPictures from '~/components/blocks/media/SmallPictures';
import VideoPlayer from '~/components/blocks/media/VideoPlayer';
/* block section */
import Section from '~/components/blocks/section/Section';
/* block texts */
import Button from '~/components/blocks/texts/Button';
import Expandable from '~/components/blocks/texts/Expandable';
import ExternalLink from '~/components/blocks/texts/ExternalLink';
import Heading from '~/components/blocks/texts/Heading';
import Marquee from '~/components/blocks/texts/Marquee';
import Richtext from '~/components/blocks/texts/Richtext';

//eslint-disable-next-line
export default defineNuxtPlugin({
    name: 'BlockFactoryPlugin',
    async setup(nuxtApp) {
        BlockFactory.register('data.featured-project-list', DataFeatured);
        BlockFactory.register('data.custom-editorial-list', [ DataList, { home: DataHomeList } ]);
        BlockFactory.register('data.custom-employee-list', DataList);
        BlockFactory.register('data.custom-project-list',  [ DataList, { home: DataHomeList } ]);
        BlockFactory.register('data.custom-report-list', DataList);
        BlockFactory.register('data.editorial-list', [ DataList, { home: DataHomeList } ]);
        BlockFactory.register('data.employee-list', DataList);
        BlockFactory.register('data.event-list', [ DataList, { home: DataHomeList } ]);
        BlockFactory.register('data.project-list', [ DataList, { home: DataHomeList } ]);
        BlockFactory.register('data.report-list', DataList);
        BlockFactory.register('data.service-list', DataList);
        BlockFactory.register('files.download', Download);
        BlockFactory.register('headers.centered-header', HeaderCentered);
        BlockFactory.register('headers.editorial-header', HeaderEdito);
        BlockFactory.register('headers.hero-header', HeaderHero);
        BlockFactory.register('headers.isometric-header', HeaderIso);
        BlockFactory.register('media.gallery', Gallery);
        BlockFactory.register('media.small-pictures', SmallPictures);
        BlockFactory.register('media.video-player', VideoPlayer);
        BlockFactory.register('media.mp3', Download);
        BlockFactory.register('section.section', Section);
        BlockFactory.register('texts.button', Button);
        BlockFactory.register('texts.expandable', Expandable);
        BlockFactory.register('texts.external-link', ExternalLink);
        BlockFactory.register('texts.heading', Heading);
        BlockFactory.register('texts.marquee', Marquee);
        BlockFactory.register('texts.richtext', Richtext);
        nuxtApp.vueApp.component('BlockFactory', BlockFactory);
    },
  });


