<template>
  <div class="collection">
    <ClientOnly v-if="!nofilters && config.filterComponent && filterInputs?.length">
      <Teleport
        v-if="hasOffset && config?.options?.filterTeleport"
        :to="config.options.filterTeleport"
      >
        <component
          :is="config.filterComponent"
          v-model="filterInputs"
          class="collection__filters app-gutter-mobile-only"
        />
      </Teleport>

      <component
        :is="config.filterComponent"
        v-else
        v-model="filterInputs"
        class="collection__filters app-gutter-mobile-only"
      />
    </ClientOnly>

    <component
      :is="config.component"
      v-if="itemsToShow && itemsToShow.length"
      :collection="itemsToShow"
      :has-offset="hasOffset"
      class="collection__list"
    />

    <UiButtonMore
      v-if="shouldDisplayMoreButton"
      :class="[
        'collection__more',
        { 'collection__more--loading': pending },
      ]"
      @click="pageIndex = pageIndex + 1"
    />
  </div>
</template>

<script setup>
import { Filter } from '~/data/filter';

const props = defineProps({
  len: { type: Number, default: 0 },
  showMore: { type: Boolean, default: false },
  nofilters: { type: Boolean, default: false },
  hasOffset: { type: Boolean, default: false },
  items: { type: Object, default: null },
  months: { type: Number, default: null },
  /* eslint-disable vue/prop-name-casing */
  'services_categories': { type: Object, default: null },
  '__component': { type: String, default: '' },
});

const filterInputs = reactive([]);
const pageIndex = ref(1);
const route = useRoute();

const config = computed(() => useDataListConfig(props['__component'], { months: props.months }));

const isMatchingUrlFilter = (tag) => {
    if (typeof route.query.filter !== 'string') return;
    return route.query.filter === `${tag.slug}`;
  };

const dataFetch = async function() {
  // Some list have embedded data, like custom lists or services list
  const embeddedData = (props.items?.data?.length ? props.items.data : null) || props['services_categories']?.data;

  if (embeddedData) {
    // custom list case, collection data are available in page data
    const pending = ref(false);
    const done = ref(true);
    const loadNext = () => { };
    const items = reactive(config.value.parser(embeddedData));
    return { items, pending, loadNext, done };
  } else {
    // collection data not available, need to fetch collection
    return useFetchDataList(config.value.name, config.value.parser, config.value.fetchParams);
  }
};

const { items: itemsData, pending, loadNext, done } = await dataFetch();
const activeFilters = computed(() => filterInputs.filter(f => f.checked));
const itemsLimit = computed(() => props.len ? pageIndex.value * props.len : 0);

const itemsFiltered = computed(() => {
  const list = itemsData;

  if (list.length <= 0) return list;

  const hasFilter = activeFilters.value.length > 0;
  if (!hasFilter) return list;

  if (typeof list[0].matchFilters !== 'function') {
    console.warn('Collection item has no matchFilter function');
    return list;
  }

  return list.filter(item => item.matchFilters(activeFilters.value));
});

const itemsToShow = computed(() => {
  const list = itemsFiltered.value;
  if (list.length <= 0 || itemsLimit.value <= 0) return list;

  return list.slice(0, itemsLimit.value);
});

const shouldDisplayMoreButton = computed(() => {
  if (!props.showMore) return false;

  const itemCount = itemsFiltered.value.length;
  if (itemCount <= 0) return false;

  const displayLimit = itemsLimit.value;
  if (displayLimit > 0 && displayLimit < itemCount) return true;

  if (done.value) return false;

  return true;
});

watch(pageIndex, (newIndex, oldIndex) => {
  if (newIndex <= oldIndex) return;

  if (itemsToShow.value.length >= itemsFiltered.value.length) {
    loadNext();
  }
});

watch(itemsData, (list) => {
  // update filters list from loaded items
  const options = config.value.options;
  if (typeof options.hasFilter === 'boolean' && !options.hasFilter) return;

  list.forEach(item => {
    if (!item || !item.tags || !item.tags.length) return;

    let filter;
    item.tags.forEach(tag => {
      if (filterInputs.find(filter => filter.id === tag.id)) return;
      filter = new Filter({ id: tag.id, label: tag.label, checked: isMatchingUrlFilter(tag) });
      filterInputs.push(filter);
    });
  });
}, { immediate: true });

watch(activeFilters, () => { pageIndex.value = 1 });
</script>

<style lang="stylus" scoped>
.collection__filters {
  margin-top 50px
  margin-bottom @margin-top

  +size(L) {
    margin-top 40px
    margin-bottom @margin-top
  }
}

.collection__more {
  width 100%

  &.collection__more--loading {
    cursor progress
    pointer-event none
  }
}

:deep(.filters-combo) {
  text-align right
  margin-top 25px
  margin-bottom @margin-top
  margin-right 0
}
</style>
