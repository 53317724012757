<template>
  <div class="timeline">
    <span v-time="time" class="timeline__time" />

    <UiProgressBar
      class="timeline__bar"
      :value="ratio"
      @click.prevent.stop=""
      @dragging="seeking"
      @dragstop="seek"
    />

    <span v-time="total" class="timeline__time" />
  </div>
</template>

<script>
const twoDigits = v => v.toString().padStart(2, '0');

const formatTime = function(s) {
  let seconds = Math.floor(s);
  let minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  seconds = seconds % 60;
  minutes = minutes % 60;
  if (hours > 0) {
    return `${twoDigits(hours)}:${twoDigits(minutes)}:${twoDigits(seconds)}`;
  }
  return `${twoDigits(minutes)}:${twoDigits(seconds)}`;
};

export default {
  directives: {
    time: {
      mounted(el, { value: milliseconds }) {
        el.innerText = formatTime(milliseconds);
      },

      updated(el, { value: milliseconds }) {
        el.innerText = formatTime(milliseconds);
      },
    },
  },

  props: {
    current: { type: Number, default: 0 },
    total: { type: Number, default: 0 },
  },

  emits: ['seek'],

  data() {
    return ({
      seekValue: -1,
    });
  },

  computed: {
    time() {
      return this.seekValue > -1 ? this.seekValue : this.current;
    },

    ratio() {
      return this.time / this.total;
    },

  },

  methods: {
    seeking(value) {
      this.seekValue = value * this.total;
    },

    seek(value) {
      this.$emit('seek', value * this.total);
      setTimeout(() => { this.seekValue = -1 }, 250);
    },
  },
};
</script>

<style lang="stylus" scoped>
.timeline {
  display flex
  justify-content space-between
  align-items center
  gap 25px
}

.timeline__bar {
  flex-grow 1
}
</style>
