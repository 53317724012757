<template>
  <LayoutCollection :row-pattern="[4]" v-bind="$props" class="theme-matr">
    <template #item="{ item }">
      <UiCardEmployee
        :photo="item.photo"
        :name="`${item.firstname} ${item.lastname}`"
        :position="item.position"
        :curriculum="item.curriculum"
        :mail="item.mail"
        :links="item.links"
      />
    </template>
  </LayoutCollection>
</template>

<style lang="stylus" scoped>
$collection-layout-breakpoint := L

:deep(.grid-layout__items) {
  border-top 0.5px solid $cl-separator
  border-bottom @border-top
}

:deep(.grid-layout__item) {
  border 0.5px solid $cl-separator
  width 100%

  +size($collection-layout-breakpoint) {
    width 24.9%
  }
}

:deep(.grid-layout__item--index-0) {
  border-left-width 1px
}

:deep(.grid-layout__item--index-3) {
  border-right-width 1px
}

:deep(.employee-card) {
  width 100%
  height 100%
}
</style>
