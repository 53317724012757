import { Entry, getEntryFromData } from '~/data/entry';
import { getMediaFromData } from '~/data/media';
import { dateModifier } from '~/data/utils';

//
// #data object definitions
//
export class Report extends Entry {
  constructor({ name, description, cover, url, published, ...entry }) {
    super(entry);
    this.name = name;
    this.description = description;
    this.cover = cover;
    this.url = url;
    this.published = published;
  }
}
//
// #data object factories
//
export function getReportFromData(apiData) {
  const data = apiData?.attributes;
  if (!data) { return null }

  const entry = getEntryFromData(apiData);
  const { name, description } = data;

  const media = getMediaFromData(data.download);
  const cover = getMediaFromData(data.cover);
  const published = dateModifier(data.publicationDate);

  return new Report({ name, description, cover, published, ...entry, url: media.url });
}

export function getReportCollectionFromData(apiData) {
  return apiData.map(data => getReportFromData(data));
}