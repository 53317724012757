import { Entry, getEntryFromData } from '~/data/entry';

//
// #data object definitions
//
export class Client extends Entry {
  constructor({ name, ...entry }) {
    super(entry);
    this.name = name;
  }
}
//
// #data object factories
//
export function getClientFromData(apiData) {
  const data = apiData?.attributes;
  if (!data) { return null }

  const entry = getEntryFromData(apiData);
  const { name } = data;
  return new Client({ name, ...entry });
}