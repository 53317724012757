<template>
  <button class="button-cube" role="button">
    <div class="button-cube__color" />

    <UiPicto
      :type="type"
      :class="[
        'button-cube__picto',
        `button-cube__picto--${type}`,
      ]"
    />
  </button>
</template>

<script>
export default {
  props: {
    type: { type: String, default: null },
  },
};
</script>

<style lang="stylus" scoped>
pos($x, $y, $z) {
  position absolute
  z-index $z
  top $y
  left $x
}

box($width, $height) {
  display block
  width $width
  height $height
}

.button-cube {
  background-color transparent
  border none
  $cube-width = 55px
  $cube-height = 62px

  position relative
  box($cube-width, $cube-height)
  background-image url('/medias/shape-cube-button.svg')

  &:after {
    content ''
    pos(0, 0, 2)
    box(100%, 100%)
    background-image url('/medias/shape-cube-button-lines.svg')
  }
}

.button-cube__color {
  pos(0, 0, 1)
  box(100%, 100%)

  background-color var(--theme-color, black)
  mask-image url('/medias/shape-cube-button-mask.svg')
}

.button-cube__picto {
  pos(50%, 50%, 3)
  display block
  transform translate(0, -15px)
}
</style>
