//
// #data object definitions
//
export class Entry {
  constructor({ id, slug, url, urlPathId, createdAt, updatedAt, publishedAt }) {
    this.id = id;
    this.slug = slug;
    this.url = url;
    this.urlPathId = urlPathId;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.publishedAt = publishedAt;
  }
}

export class CollectionEntry extends Entry {
  constructor({ tags, related, ...collectionEntry }) {
    super(collectionEntry);
    this.tags = tags;
    this.related = related;
  }

  matchFilter(filter) {
    return this.tags.findIndex(tag => filter.id === tag.id) > -1;
  }

  matchFilters(filters) {
    let match = false;
    let filterIndex = 0;
    while (!match && filterIndex < filters.length) {
      match = this.matchFilter(filters[filterIndex]);
      filterIndex++;
    }
    return match;
  }
}

export class Tag {
  constructor({ id, label, slug }) {
    this.id = id;
    this.label = label;
    this.slug = slug;
  }
}
//
// #data object factories
//
export function getEntryFromData(apiData) {
  const data = apiData?.attributes;
  if (!data) return;

  const id = apiData?.id;
  const { url_path_id: urlPathId, url_path: url } = data;

  return new Entry({ ...data, id, url, urlPathId });
}

export function getCollectionEntryFromData(apiData, getRelatedFromData = () => {}) {
  const data = apiData?.attributes;
  if (!data) { return null }

  const collectionEntry = getEntryFromData(apiData);
  const tags = data.tags?.data?.map(tagData => getTagFromData(tagData));
  const related = data.related?.data?.map(relatedData => getRelatedFromData(relatedData));

  return new CollectionEntry({ ...collectionEntry, tags, related });
}

export function getTagFromData(apiData) {
  const data = apiData?.attributes;
  if (!data) { return null }

  const { id } = apiData;
  const { label, slug } = data;

  return new Tag({ id, label, slug });
}