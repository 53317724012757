<template>
  <LayoutList
    v-bind="$props"
    :group-by="item => item.category.name"
    :group-sort="groupSort"
    class="theme-serv"
  >
    <template #item="{ item }">
      <UiCardService
        :title="item.name"
        :href="item.url"
      />
    </template>
  </LayoutList>
</template>

<script>
export default {
  props: {
    collection: { type: Array, default: null },
  },

  computed: {
    categories() {
      if (!this.collection) return null;
      const categories = {};
      this.collection.forEach(item => categories[item.category.name] = item.category.id);
      return categories;
    },
  },
  methods: {
    groupSort() {
      /* services are already grouped by categories ordered in BO */
      return 0;
    },
  },
};
</script>

<style lang="stylus" scoped>
$collection-layout-breakpoint := L

:deep(.list__item) {
  border-top 1px solid $cl-separator
  border-bottom @border-top
  margin-bottom -1px
}

:deep(.list__label) {
  +size($collection-layout-breakpoint) {
    border-bottom 1px solid white
  }
}

:deep(.list__label-text) {
  @extends .typo-h1-small
  text-align center
  margin 0

  box-sizing border-box
  padding 25px 15px

  +size($collection-layout-breakpoint) {
    text-align right
    padding 50px
  }
}

:deep(.list__group:first-child) {
  .list__label {
    +size($collection-layout-breakpoint) {
      fold(126px, 72px)
    }
  }
}
</style>
