<template>
  <NuxtLayout>
    <div v-if="error" class="error theme-matr">
      <BlocksHeadersHeaderIso
        class="app-gutter"
        :title="`${error.statusCode}`"
        :subtitle="`${error.statusMessage} - Vous êtes perdu dans la matrice ?`"
      />
      <UiButtonFlat
        class="error__button"
        type="right"
        @click="handleError"
      >
        Retour à l'accueil
      </UiButtonFlat>
    </div>
  </NuxtLayout>
</template>

<script setup>
defineProps({ error: { type: Object, default: () => ({}) } });
const handleError = () => clearError({ redirect: '/' });
</script>

<style lang="stylus">
.error {
  padding 50px 0
  +size(L) {
    padding 100px 0
  }
}

.error__button {
  justify-content center
}
</style>
