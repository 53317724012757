<template>
  <div class="gallery">
    <LayoutGallery
      v-if="items && items.length"
      :slides="items"
      :options="{
        itemsToShow: 1.1,
        breakpoints: {
          1024: {
            itemsToShow: 1.415,
          },
        },
      }"
      class="gallery__layout"
    >
      <template #slide="{ slide, isActive }">
        <Picture
          :src="$mediaUrl(slide?.url)"
          :alt="slide?.alt"
          :legend="slide?.caption"
          :fix-ratio="true"
          :grayscale="!isActive"
          :hide-legend="!isActive"
          class="gallery__item"
        />
      </template>

      <template #previous>
        <UiButtonFlat type="left" name="Précédent" />
      </template>

      <template #next>
        <UiButtonFlat type="right" name="Suivant" />
      </template>
    </LayoutGallery>
  </div>
</template>

<script>
import { getMediaFromGalleryItemData } from '~/data/media';

export default {
  props: {
    content: { type: Array, default: null },
  },

  computed: {
    items() {
      const items = this.content?.filter(item => item.picture !== null);
      return items.map(item => getMediaFromGalleryItemData(item));
    },
  },
};
</script>

<style lang="stylus" scoped>
$skew-angle = 33deg

$pseudo-background {
  content ''
  position absolute
  top 0
  left 50%

  width 90%
  height 100%

  transform translateX(-50%)
  background-color var(--theme-color, white)

  +size(L) {
    width 80%
  }
}

.gallery {
  position relative
  &:before {
    @extends $pseudo-background
    z-index -1
    transform-origin top right
    transform translateX(-149.95%) skewY($skew-angle)
  }
  &:after {
    @extends $pseudo-background
    z-index -1
    transform-origin bottom left
    transform translateX(49.95%) skewY($skew-angle)
  }
}

.gallery__layout {
  position relative
  z-index 1
  &:before {
    @extends $pseudo-background
  }
}

.gallery__item {
  width 100%
}

:deep(.gallery-layout__item) {
  line-height 0
  padding 55px 7px

  +size(L) {
    padding 50px 25px
  }
}

:deep(.gallery-layout__navigation) {
  --theme-color black
  --theme-color-text white

  position absolute
  z-index 1
  top 12px
  right 6%

  +size(L) {
    top 70px
    right 18%
  }

  display flex
  gap 10px
}
</style>
