<template>
  <UiBackgroundIso
    :class="[
      'edito-collection',
      { 'edito-collection--offset': hasOffset },
      'theme-trib',
      'app-gutter-desktop-only',
    ]"
    offset-y="-90px"
  >
    <ClientOnly v-if="hasOffset && firstItem">
      <Teleport to=".header-edito__card">
        <UiCardEdito
          v-bind="itemToCardProps(firstItem)"
          class="edito-top-card"
        />
      </Teleport>

      <template #fallback>
        <UiCardEdito
          v-bind="itemToCardProps(firstItem)"
          class="edito-top-card"
        />
      </template>
    </ClientOnly>

    <LayoutCollection
      :row-pattern="[3, 2, 2]"
      v-bind="$props"
      :collection="hasOffset ? collection.slice(1) : collection"
    >
      <template #item="{ item, position }">
        <UiCardEdito
          :small="position.repeatPosition !== 0 && position.repeatPosition !== 3"
          v-bind="itemToCardProps(item)"
        />
      </template>
    </LayoutCollection>
  </UiBackgroundIso>
</template>

<script>
export default {
  props: {
    collection: { type: Array, default: null },
    hasOffset: { type: Boolean, default: false },
  },

  computed: {
    firstItem() {
      if (!this.collection?.length) return null;
      return this.collection[0];
    },
  },

  methods: {
    itemToCardProps(item) {
      return {
        buttonLabel: item.published,
        href: item.url,
        image: item.cover,
        name: item.title,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
$collection-layout-breakpoint := L

.edito-top-card
:deep(.grid-layout__item) {
  padding 50px 60px
  box-sizing border-box
  width 100%
}

.edito-top-card {
  background-color var(--theme-color, white)
}

:deep(.grid-layout__item) {
  &:nth-child(odd) {
    background-color var(--theme-color, white)
    .edito-collection--offset & {
      background-color transparent
    }
  }

  &:nth-child(even) {
    .edito-collection--offset & {
      background-color var(--theme-color, white)
    }
  }
}

+size($collection-layout-breakpoint) {
  $card-h-gutter = 32px

  :deep(.edito-card__image) {
    clip-path none
  }

  :deep(.grid-layout__items) {
    gap 75px 0
    justify-content space-between
  }

  :deep(.grid-layout__item) {
    width 21%
    width s('calc(25% - %s)', $card-h-gutter)
    padding 0
    &:nth-child(even),
    &:nth-child(odd) {
      background-color transparent
      .edito-collection--offset & {
        background-color transparent
      }
    }
  }

  :deep(.grid-layout__item--pattern-0) {
    &.grid-layout__item--index-0 {
      width 50%
      width s('calc(50% + %s)', ($card-h-gutter / 2))
    }
  }

  :deep(.grid-layout__item--pattern-1) {
    &.grid-layout__item--index-0 {
      width 50%
      margin-left auto
      margin-right ($card-h-gutter / 2)
    }

    &.grid-layout__item--index-1 {
      margin-right auto
      margin-left ($card-h-gutter / 2)
    }
  }

  :deep(.grid-layout__item--pattern-2) {
    width 33%

    &.grid-layout__item--index-0 {
      margin-right 15%
    }

    &.grid-layout__item--index-1 {
      margin-left 15%
    }
  }

  .edito-top-card {
    padding 0
    background-color transparent
  }
}
</style>
