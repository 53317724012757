import { CollectionEntry, getCollectionEntryFromData } from '~/data/entry';
import { getMediaFromData } from './media';
import { dateModifier } from './utils';

//
// #data object definitions
//
export class Edito extends CollectionEntry {
  constructor({ title, cover, author, published, ...collectionEntry }) {
    super(collectionEntry);
    this.title = title;
    this.cover = cover;
    this.author = author;
    this.published = published;
  }
}
//
// #data object factories
//
export function getEditoFromData(apiData) {
  const data = apiData?.attributes;
  if (!data) { return null }

  const collectionEntry = getCollectionEntryFromData(apiData, getRelatedCardFromData);
  const cover = getMediaFromData(data.cover);
  const published = dateModifier(data.publicationDate || collectionEntry.publishedAt);
  const { title, author } = data;

  return new Edito({ title, cover, author, published, ...collectionEntry });
}

export function getEditoCollectionFromData(apiData) {
  return apiData.map(data => getEditoFromData(data));
}

function getRelatedCardFromData(apiData) {
  const edito = getEditoFromData(apiData);
  return {
    cover: edito.cover,
    name: edito.title,
    label: edito.published,
    href: edito.url,
    target: '_self',
  };
}