<template>
  <div class="collection-home">
    <div class="collection-home__head app-gutter">
      <UiTitleArrow v-if="config.title" :content="config.title" />

      <template v-if="config.more">
        <BlocksTextsButton
          label="Tout voir"
          :url="config.more"
          type="right"
          class="collection-home__more--desktop"
        />

        <BlocksTextsButton
          :url="config.more"
          type="right"
          class="collection-home__more--mobile"
        />
      </template>
    </div>

    <BlocksDataList v-bind="$props" nofilters class="collection-home__list" />
  </div>
</template>

<script>
function getCollectionConfig(name) {
  switch (name) {
    case 'data.editorial-list':
    case 'data.custom-editorial-list':
      return { title: 'Points de vue', more: '/points-de-vue' };
    case 'data.event-list':
      return { title: 'À venir', more: '/agenda' };
    case 'data.project-list':
    case 'data.custom-project-list':
      return { title: 'Nos réalisations', more: '/realisations' };
  }

  return {};
}

export default {
  props: {
    len: { type: Number, default: 0 },
    showMore: { type: Boolean, default: false },
    hasOffset: { type: Boolean, default: false },
    items: { type: Object, default: null },
    /* eslint-disable vue/prop-name-casing */
    '__component': { type: String, default: '' },
  },

  computed: {
    config() {
      return getCollectionConfig(this.$props['__component']);
    },
  },
};
</script>

<style lang="stylus" scoped>
.collection-home {
  margin-top 30px
}

.collection-home__head {
  display flex
  justify-content space-between
  align-items center
  margin-bottom 25px
  +size(L) {
    margin-bottom 30px
  }
}

.collection-home__more--mobile {
  margin-left auto
  +size(M) {
    display none;
  }
}
.collection-home__more--desktop {
  position relative
  z-index 1
  margin-left auto
  +below(M) {
    display none;
  }
}
</style>
