<template>
  <button class="button-more typo-button-large">
    voir plus
  </button>
</template>

<style lang="stylus" scoped>
.button-more {
  @extends $reset-button
  height 113px
  text-align center
  color var(--theme-color-text, white)
  background-color var(--theme-color, black)
  cursor pointer

  &:hover {
    text-decoration underline
  }
}
</style>
