<template>
  <div class="button-block">
    <component
      :is="component"
      :to="to"
      :target="target"
      class="button-block__container"
    >
      <UiButtonCube
        class="button-block__cube"
        :type="picto"
      />

      <slot>
        <div class="button-block__label">
          {{ label }}
        </div>
      </slot>
    </component>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: null },
    target: { type: String, default: null },
    to: { type: String, default: null },
    picto: { type: String, default: null },
    component: { type: [Object, String], default: resolveComponent('NuxtLink') },
  },
};
</script>

<style lang="stylus" scoped>
.button-block__container {
  @extends $reset-button !optional
  position relative

  display block
  background-color $cl-flat
  border-bottom 1px solid black
  margin 1em 0 2em
  width 100%

  text-decoration none
  font-weight normal
  cursor pointer
}

.button-block__cube {
  position absolute
  bottom -17px
  left -2px
  transition transform 0.2s ease-in

  .button-block__container:hover & {
    transform translate3d(0, 8%, 0)
  }
}

.button-block__label {
  box-sizing border-box
  padding 20px 25px 20px 75px
}
</style>
