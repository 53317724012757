/* eslint-disable */
function requestBuilder(slug, params, page) {
  const { sort = '', raw = '' } = params || {};
  const url = `/api/${slug}?pagination[page]=${page}&sort=${sort}&${raw}`;
  const id = `fetch_collection_${slug}_${page}`;
  return { url, id };
}

function getCollection(slug, parser, fetchParams, pageIndex = 1) {
  return new Promise(async (resolve) => {
    const request = requestBuilder(slug, fetchParams, pageIndex);
    const { data, error } = await useAsyncData(request.id, () => $fetch(request.url));

    watch(data, (incoming) => {
      const items = [];
      let done = false;

      if (incoming.data) {
        items.push(...parser(incoming.data));
      }

      if (incoming.meta) {
        const { pageCount, page } = incoming?.meta?.pagination;
        done = page >= pageCount;
      }

      /* parse data here */
      resolve({ items, done });
    }, { immediate: true });
  });
}

export default async function (collectionSlug, collectionParser, collectionSort) {
  const pageIndex = ref(0);
  const items = reactive([]);
  const pending = ref(false);
  const done = ref(false);

  const loadNext = async () => {
    pending.value = true;
    pageIndex.value += 1;
    const { items: newItems, done: loadComplete } = await getCollection(collectionSlug, collectionParser, collectionSort, pageIndex.value);
    items.push(...newItems);
    pending.value = false;
    done.value = loadComplete;
  }

  loadNext();

  return { items, pending, done, loadNext };
}