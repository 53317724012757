<template>
  <LayoutCard
    :class="[
      'employee-card',
      { 'employee-card--link': !!curriculum },
    ]"
    @click="!!curriculum && (open = true)"
  >
    <template #head>
      <UiMediaImage
        :image="photo"
        fit
        class="employee-card__image"
        width="360"
        height="360"
      />
    </template>

    <template v-if="!!curriculum" #overlay>
      <UiButtonFlat class="employee-card__button" type="more" />
    </template>

    <template #body>
      <p class="employee-card__title typo-h3">
        {{ name }}
      </p>
      <p class="employee-card__text">
        {{ position }}
      </p>
    </template>
  </LayoutCard>

  <Teleport v-if="open" to=".page__panel">
    <ThePanel class="employee-card__detail" @close="open = false">
      <UiDetailEmployee v-bind="$props" />
    </ThePanel>
  </Teleport>
</template>

<script>
import { Media } from '~/data/media.js';

export default {
  props: {
    photo: { type: Media, default: null },
    name: { type: String, default: null },
    position: { type: String, default: null },
    curriculum: { type: String, default: null },
    mail: { type: String, default: null },
    links: {type: Array, default: ()=>[]},
  },

  data() {
    return {
      open: false,
    };
  },
};
</script>

<style lang="stylus" scoped>
card-color-animation('.employee-card', '.employee-card__title, .employee-card__text')
card-mask-animation('.employee-card', '.employee-card__image')

.employee-card {
  box-sizing border-box
  padding 50px

  +size(L) {
    padding 60px
  }
}

.employee-card__image {
  aspect-ratio 1/1
  filter grayscale(1)
}

.employee-card__title {
  margin-bottom 0
}

.employee-card__text {
  margin 0
}

.employee-card__button {
  --theme-color white
  --theme-color-text black
}

:deep(.card-layout__overlay) {
  bottom 20px
  right 20px
}
</style>
