<template>
  <div class="progress" @mousedown.stop="dragStart">
    <span class="progress__background" />
    <span class="progress__bar" :style="`transform:scaleX(${ratio})`" />
    <span class="progress__cursor" :style="`transform:translate(${x}px, -50%)`" />
  </div>
</template>

<script>
function rangeRatio(value, min, max) {
  return (value - min) / (max - min);
}

function clamp(value, min = 0, max = 1) {
  return Math.max(Math.min(value, max), min);
}

export default {
  props: {
    start: { type: Number, default: 0 },
    end: { type: Number, default: 1 },
    value: { type: Number, default: 0 },
  },

  emits: ['dragging', 'dragstop'],

  data() {
    return ({
      dragXRange: [0, 0],
      dragX: -1,
    });
  },

  computed: {
    ratio() {
      return rangeRatio(this.dragX > -1 ? this.dragX : this.value, this.start, this.end);
    },

    x() {
      return this.ratio * this.getWidh();
    },
  },

  methods: {
    getWidh() {
      return this.$el ? this.$el.offsetWidth : 0;
    },

    dragStart() {
      this.dragXRange[0] = (this.$el.getBoundingClientRect()).left;
      this.dragXRange[1] = this.dragXRange[0] + this.getWidh();

      window.addEventListener('mousemove', this.dragging);
      window.addEventListener('mouseup', this.dragStop);
      window.addEventListener('mouseleave', this.dragStop);
    },

    dragStop(e) {
      this.$emit('dragstop', this.getDragValue(e));
      this.dragX = -1;
      window.removeEventListener('mousemove', this.dragging);
      window.removeEventListener('mouseup', this.dragStop);
      window.removeEventListener('mouseleave', this.dragStop);
    },

    dragging(e) {
      this.dragX = this.getDragValue(e);
      this.$emit('dragging', this.dragX);
    },

    getDragValue(event) {
      return clamp(rangeRatio(event.clientX, this.dragXRange[0], this.dragXRange[1]));
    },
  },
};
</script>

<style lang="stylus" scoped>
.progress {
  position relative
  box-sizing border-box
  height 12px
  padding-top 5px
}

.progress__bar,
.progress__background {
  position absolute
  top 50%
  display block
  width 100%
  height 1px
  background-color $cl-separator
}

.progress__bar {
  background-color currentColor
  z-index 1
  transform-origin top left
}

.progress__cursor {
  $size = 6px
  position absolute
  left -($size / 2)
  top 50%

  width $size
  height @width

  background-color currentColor
  border-radius $size
  opacity 0
  transition opacity 0.2s ease-in 1s

  .progress:hover & {
    opacity 1
    transition opacity 0.2s ease-in
  }
}
</style>
