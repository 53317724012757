//
// #data object definitions
//
export class MediaFormat {
  constructor({ name, url, ext, size, width, height }) {
    this.ext = ext;
    this.url = url;
    this.name = name;
    this.size = size;
    this.width = width;
    this.height = height;
  }
}

export class Media extends MediaFormat {
  constructor({ name, alt, caption, width, height, formats, ext, size, url, previewUrl, label }) {
    super({ name, url, ext, size, width, height });
    this.label = label;
    this.alt = alt;
    this.caption = caption;
    this.previewUrl = previewUrl;
    this.formats = {};
    Object.keys(formats || {}).forEach(name => {
      this.formats[name] = new MediaFormat(formats[name]);
    });
  }
}

export class DownloadMedia {
  constructor(media, label) {
    this.media = media;
    this.label = label;
  }
}
//
// #data object utils
//
function formatSize(size) {
  var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ['o', 'Ko', 'Mo', 'Go', 'To'][i];
}
//
// #data object factories
//
export function getMediaFormatFromApi(data) {
  const size = formatSize(data?.size || 0);
  const url = data?.url;
  return new MediaFormat({ ...data, size, url });
}

export function getMediaFromData(data, label) {
  const mediaData = data?.data?.attributes;
  const formats = {};

  Object.keys(mediaData?.formats || {}).forEach(name => {
    formats[name] = getMediaFormatFromApi(mediaData.formats[name]);
  });

  const alt = mediaData?.alternativeText;
  const previewUrl = mediaData?.previewUrl;
  return new Media({ ...mediaData, ...getMediaFormatFromApi(mediaData), label, alt, previewUrl, formats });
}

export function getMediaFromGalleryItemData(data) {
  const media = getMediaFromData(data?.picture);
  media.caption = data?.legend || media.caption;
  return media;
}
