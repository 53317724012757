<template>
  <UiButtonFlat
    :href="url"
    type="right"
    :class="['button-text', theme]"
  >
    {{ label }}
  </UiButtonFlat>
</template>

<script setup>
const { theme: configTheme = null } = await useAppConfig();

const props = defineProps({
  label: { type: String, default: null },
  url: { type: String, default: null },
});

const theme = computed(() => {
  if (!configTheme) return 'theme-matr';
  if (!props.url) return configTheme.default || 'theme-matr';
  const [, themeRoute] = props.url.split('/');
  return configTheme[themeRoute] || configTheme.default || 'theme-matr';
});
</script>