<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <div>
    <template v-if="type === 'more'">
      <svg class="picto picto__half" viewBox="0 0 21 21">
        <rect x="0" y="10" width="21" height="1" class="picto__fill" />
        <rect x="10" y="0" width="1" height="21" class="picto__fill" />
      </svg>
    </template>

    <template v-if="type === 'minus'">
      <svg class="picto picto__half" viewBox="0 0 21 21">
        <rect x="0" y="10" width="21" height="1" class="picto__fill" />
      </svg>
    </template>

    <template v-if="type === 'left'">
      <svg class="picto picto__small" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 17L0.999999 9L9 1" stroke-linecap="round" stroke-linejoin="round" class="picto__stroke" />
      </svg>
    </template>

    <template v-if="type === 'right'">
      <svg class="picto picto__small" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 17L9 9L1 1" stroke-linecap="round" stroke-linejoin="round" class="picto__stroke" />
      </svg>
    </template>

    <template v-if="type === 'download-flat'">
      <svg class="picto picto__half" viewBox="-2 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.6088 11.1295V13.7382C14.6088 14.6987 13.8302 15.4773 12.8697 15.4773H2.43493C1.47444 15.4773 0.695801 14.6987 0.695801 13.7382L0.695801 11.1295M3.77887 8.05613L7.25713 11.5344M7.25713 11.5344L10.7354 8.05613M7.25713 11.5344V1.09961" stroke="#292929" stroke-linecap="round" stroke-linejoin="round" class="picto__stroke" />
      </svg>
    </template>


    <template v-if="type === 'download'">
      <svg class="picto" width="33" height="20" viewBox="0 0 33 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.4487 16.0196L17.7618 17.5218C16.7726 18.0749 15.1749 18.0639 14.1934 17.4972L3.52999 11.3407C2.54845 10.774 2.55471 9.86623 3.54397 9.31313L6.23079 7.8109M12.5468 7.8601L12.5189 11.9152M12.5189 11.9152L19.6557 11.9644M12.5189 11.9152L23.2661 5.90634" stroke-linecap="round" stroke-linejoin="round" class="picto__stroke" />
      </svg>
    </template>

    <template v-if="type === 'link'">
      <svg class="picto" width="40" height="23" viewBox="0 0 40 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_207_49369)">
          <path d="M19.5556 14.7518L21.2877 15.7518C23.2008 16.8563 26.3149 16.8778 28.2431 15.7997C30.1713 14.7216 30.1835 12.9522 28.2704 11.8477L26.5383 10.8477M16.9848 9.2997L23.913 13.2997M21.3421 7.84766L19.6101 6.84766C17.6969 5.74309 14.5829 5.72162 12.6546 6.7997C10.7264 7.87779 10.7142 9.64718 12.6274 10.7518L14.3595 11.7518" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="picto__stroke" />
        </g>
        <defs>
          <clipPath id="clip0_207_49369">
            <rect width="23" height="23" fill="white" transform="matrix(0.866025 0.5 -0.872837 0.488012 20.0752 0)" />
          </clipPath>
        </defs>
      </svg>
    </template>

    <template v-if="type === 'mail'">
      <svg class="picto" width="30" height="17" viewBox="0 0 30 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.3043 1.28602C13.6201 1.29027 13.935 1.36193 14.1741 1.5L28.0305 9.5C28.2697 9.63807 28.3878 9.81641 28.3854 9.99313M13.3043 1.28602C12.9884 1.28178 12.6717 1.34495 12.429 1.47655L1.87916 7.19509C1.39361 7.45827 1.38774 7.89549 1.86603 8.17163L15.7224 16.1716C16.2007 16.4478 16.9821 16.4583 17.4676 16.1951L28.0174 10.4765C28.2602 10.345 28.383 10.1698 28.3854 9.99313M13.3043 1.28602L13.2086 8.40784C13.1983 9.17057 14.295 9.80373 15.6581 9.82205L28.3854 9.99313" stroke-linecap="round" stroke-linejoin="round" class="picto__stroke" />
      </svg>
    </template>

    <template v-if="type === 'play'">
      <svg class="picto" width="33" height="19" viewBox="0 0 33 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.1312 2.42164L21.3986 12.2883L4.22602 9.77667L17.1312 2.42164Z" stroke-linecap="round" stroke-linejoin="round" class="picto__stroke" />
      </svg>
    </template>
  </div>
</template>

<script>
export default {
  props: { type: { type: String, default: null }},
};
</script>

<style lang="stylus" scoped>
.picto {
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)
}

.picto__small {
  width 25%
}

.picto__half {
  width 50%
}

.picto__stroke {
  stroke currentColor
}

.picto__fill {
  fill currentColor
}
</style>
