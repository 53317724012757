import { getMediaFromData } from '~/data/media.js';
//
// #data object definitions
//
export class Partner {
  constructor({ name, logo, slug }) {
    this.name = name;
    this.logo = logo;
    this.slug = slug;
  }
}
//
// #data object factories
//
export function getPartnerFromData(apiData) {
  const data = apiData?.attributes;
  if (!data) { return null }

  const { name, slug } = data;
  const logo = getMediaFromData(data.logo);
  return new Partner({ name, slug, logo });
}