import { dateModifier } from './utils';
import { Entry, getEntryFromData } from '~/data/entry';
import { getMediaFromData } from './media';

//
// #data object definitions
//
export class Event extends Entry {
  constructor({ name, date, shortDate, dateLabel, cover, link, ...entry }) {
    super(entry);
    this.name = name;
    this.date = date;
    this.shortDate = shortDate;
    this.dateLabel = dateLabel;
    this.cover = cover;
    this.link = link;
  }
}
//
// #data object factories
//
export function getEventFromData(apiData) {
  const data = apiData?.attributes;
  if (!data) { return null }

  const entry = getEntryFromData(apiData);

  const { name, link, date, dateLabel } = data;
  const shortDate = date ? dateModifier(date) : null;
  const cover = getMediaFromData(data.cover);

  return new Event({ name, date, shortDate, dateLabel, cover, link, ...entry });
}

export function getEventCollectionFromData(apiData) {
  return apiData.map(data => getEventFromData(data));
}