<template>
  <div class="player">
    <div class="player__wrapper">
      <VideoPlayer
        :poster="posterMedia.url"
        :video="videoMedia.url"
        class="player__video"
      />
    </div>
  </div>
</template>

<script>
import { getMediaFromData } from '~/data/media';

export default {
  props: {
    controls: { type: Boolean, default: false },
    muted: { type: Boolean, default: false },
    loop: { type: Boolean, default: false },
    autoplay: { type: Boolean, default: false },
    posterframe: { type: Object, default: null },
    video: { type: Object, default: null },
  },

  computed: {
    posterMedia() {
      return getMediaFromData(this.posterframe);
    },

    videoMedia() {
      return getMediaFromData(this.video);
    },
  },
};
</script>

<style lang="stylus" scoped>
.player {
}

.player__wrapper {
  @extends .app-media-frame, .app-width-default
  margin-left auto
  margin-right auto
  line-height 0
  box-sizing content-box
  background-color var(--theme-color, white)
  padding 15px 25px

  +size(L) {
    padding 50px 122px
  }
}

.player__video {
  width 100%
}
</style>
