/* eslint-disable no-undef */
export default defineAppConfig({
  partners: {
    logo: { data: { attributes: { url: '/uploads/qualiopi_5ef713e9b8.png' } } },
    title: 'Actions co-financées par',
    collection: 'partners',
  },

  theme: {
    default: 'theme-home',
    adn: 'theme-matr',
    realisations: 'theme-prob',
    'points-de-vue': 'theme-trib',
    rapports: 'theme-rapp',
    agenda: 'theme-agen',
    'savoir-faire': 'theme-serv',
  },
});