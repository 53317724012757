<template>
  <div
    :class="[
      'event-card',
      { 'event-card--link': !!href },
    ]"
  >
    <UiMediaImage
      :image="image"
      :fit="true"
      class="event-card__cover"
      width="150"
      ratio="1"
    />

    <div class="event-card__text">
      <p class="event-card__head typo-small">
        {{ head }}
      </p>
      <p class="event-card__title typo-title-list-item">
        {{ title }}
      </p>
      <UiTag
        v-if="buttonLabel"
        class="event-card__button"
        :label="buttonLabel"
        :model-value="true"
      />
    </div>

    <UiOverlayLink :label="buttonLabel" :href="href" :target="target" />
  </div>
</template>

<script>
import { Media } from '~/data/media.js';

export default {
  props: {
    image: { type: Media, default: null },
    head: { type: String, default: null },
    title: { type: String, default: null },
    href: { type: String, default: null },
    target: { type: String, default: null },
    buttonLabel: { type: String, default: null },
  },
};
</script>

<style lang="stylus" scoped>
card-mask-animation('.event-card', '.event-card__cover', '.event-card__title', false, true)

.event-card {
  position relative
  display flex
  padding 25px 15px

  +size(L) {
    padding 50px
  }
}

.event-card__cover {
  aspect-ratio 1/1

  width 80px
  height @width

  +size(L) {
    width 150px
    height @width
  }
}

.event-card__text {
  padding-left 15px

  +size(L) {
    padding-left 50px
  }
}

.event-card__head {
  margin-top 0
}

.event-card__button {
  color black
  background-color var(--theme-color, white)

  +size(L) {
    font-size 15px
  }
}
</style>
