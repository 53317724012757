import { getEditoCollectionFromData } from '~/data/edito';
import { getEmployeeCollectionFromData } from '~/data/employee';
import { getEventCollectionFromData } from '~/data/event';
import { getProjectCollectionFromData } from '~/data/project';
import { getReportCollectionFromData } from '~/data/report';
import { getServiceCollectionFromCategoryListData } from '~/data/service';

import {
  CollectionEdito,
  CollectionEmployee,
  CollectionEvent,
  CollectionReport,
  CollectionService,
  CollectionProject,
  UiFilters,
  UiFiltersCombo,
} from '#components';

const eventFilter = (monthCount) => {
  const today = new Date(Date.now());
  const twoDigits = (n) => n < 10 ? `0${n}` : `${n}`;
  let out = `[filters][date][$gte]=${today.getFullYear()}-${twoDigits(today.getMonth() + 1)}-01`;
  if (!monthCount) return out;

  const limit = new Date(today.setMonth(today.getMonth() + monthCount));
  return `${out}&[filters][date][$lt]=${limit.getFullYear()}-${twoDigits(limit.getMonth() + 1)}-01`;
};

export default function(name, fetchOptions) {
  /* eslint-disable no-fallthrough */
  const options = {};
  switch (name) {
    case 'data.custom-editorial-list':
      options.hasFilter = false;
    case 'data.editorial-list':
      options.filterTeleport = '.header-edito__filters';

      return {
        component: CollectionEdito,
        filterComponent: UiFiltersCombo,
        parser: getEditoCollectionFromData,
        fetchParams: { sort: 'publicationDate:DESC', raw: 'populate[0]=cover&populate[1]=tags' },
        name: 'editorials',
        options,
      };
    case 'data.custom-employee-list':
      options.hasFilter = false;
    case 'data.employee-list':
      return {
        component: CollectionEmployee,
        filterComponent: UiFilters,
        parser: getEmployeeCollectionFromData,
        fetchParams: { sort: 'rank:ASC', raw:'populate[0]=photo&populate[1]=links' },
        name: 'employees',
        options,
      };
    case 'data.event-list':
      return {
        component: CollectionEvent,
        filterComponent: UiFilters,
        parser: getEventCollectionFromData,
        fetchParams: { raw: eventFilter(fetchOptions.months), sort:"date:ASC" },
        name: 'events',
        options,
      };
    case 'data.custom-report-list':
      options.hasFilter = false;
    case 'data.report-list':
      return {
        component: CollectionReport,
        filterComponent: UiFilters,
        parser: getReportCollectionFromData,
        fetchParams: { sort: 'publicationDate:DESC' },
        name: 'reports',
        options,
      };
    case 'data.service-list':
      return {
        component: CollectionService,
        filterComponent: UiFilters,
        parser: getServiceCollectionFromCategoryListData,
        name: 'services-categories',
        options,
      };
    case 'data.custom-project-list':
      options.hasFilter = false;
    default:
      return {
        component: CollectionProject,
        filterComponent: UiFilters,
        parser: getProjectCollectionFromData,
        fetchParams: { sort: 'rank:ASC', raw:"populate[0]=cover&populate[1]=client&populate[2]=tags" },
        name: 'projects',
        options,
      };
  }
}