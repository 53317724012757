//
// #data object definitions
//
export class Filter {
  constructor({ id, label, checked }) {
    this.id = id;
    this.label = label;
    this.checked = checked;
  }
}
//
// #data object factories
//
export function getFilterFromData(apiData) {
  const { id } = apiData;
  const data = apiData?.attributes;
  return new Filter({ id, label: data.label, checked: false });
}

export function getDefaultFilter() {
  return new Filter({ id: -1, label: 'Tous', checked: true });
}