<template>
  <LayoutList
    v-bind="$props"
    :group-by="(item) => item.shortDate"
    :group-sort="groupSort"
    class="theme-agen"
  >
    <template #item="{ item }">
      <UiCardEvent
        :image="item.cover"
        :head="item.dateLabel"
        :title="item.name"
        :href="item.link?.url"
        :target="item.link?.target"
        :button-label="item.link?.label"
      />
    </template>
  </LayoutList>
</template>

<script>
const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
const shortDateToNumber = (date) => {
  const [, month, year] = date.match(/^(.*)\s([0-9]{2,})$/);
  const monthDigit = months.indexOf(month) + 11;
  return Number(year + monthDigit) - 10;
};

export default {
  methods: {
    groupSort(a, b) {
      const dateA = shortDateToNumber(a.label);
      const dateB = shortDateToNumber(b.label);
      if (dateA > dateB) { return 1 }
      if (dateA < dateB) { return -1 }
      return 0;
    },
  },
};
</script>

<style lang="stylus" scoped>
$collection-layout-breakpoint := L

:deep(.list__item) {
  border-top 1px solid $cl-separator
  border-bottom @border-top
  margin-bottom -1px
}

:deep(.list__label) {
  +size($collection-layout-breakpoint) {
    border-bottom 1px solid white
  }
}

:deep(.list__label-text) {
  @extends .typo-h1-small
  text-align center
  margin 0

  box-sizing border-box
  padding 25px 15px

  +size($collection-layout-breakpoint) {
    text-align right
    padding 50px
  }
}

:deep(.list__group:first-child) {
  .list__label {
    +size($collection-layout-breakpoint) {
      fold(126px, 72px)
    }
  }
}
</style>
