<template>
  <NuxtLink class="overlay-link" v-bind="$props">
    <span class="overlay-link__label">{{ label }}</span>
  </NuxtLink>
</template>

<script>
export default {
  props: {
    label: { type: String, default: null },
  },
};
</script>

<style lang="stylus" scoped>
.overlay-link {
  position absolute
  width 100%
  height 100%
  top 0
  right 0
  bottom 0
  left 0
}
.overlay-link__label {
  display block
  overflow hidden
  height 0
  opacity 0
}
</style>
