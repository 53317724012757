const RegisteredBlocks = {};

export default {
  name: 'block-factory',
  props: {
    content: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: 'default',
    },
  },
  register(key, components) {
    if (Array.isArray(components)) {
      RegisteredBlocks[key] = {
        default: components[0],
        ...components[1],
      };
    } else {
      RegisteredBlocks[key] = {
        default: components,
      };
    }
  },
  render(createElement) {
    const elements = [];
    this.blocks.forEach((block) => {
      // eslint-disable-next-line
      elements.push(this.createComponent(h, block));
    });
    return elements;
  },
  computed: {
    blocks() {
      const blocks = [];
      const items = this.content;
      for (let i = 0; i < items.length; i += 1) {
        const item = items[i];
        
        if(RegisteredBlocks[item.__component]) {
          const block = RegisteredBlocks[item.__component][this.type] ? RegisteredBlocks[item.__component][this.type] : RegisteredBlocks[item.__component].default;
          if (block) {
            blocks.push({
              is: block,
              data: {
                parentType: this.type, ...item,
              },
            });
          } 
        } else {
          console.info('unknown component', item.__component);
        }
      }
      return blocks;
    },
    registered() {
      return RegisteredBlocks;
    },
  },
  methods: {
    createComponent(createElement, block) {
      return createElement(block.is, block.data);
    },
  },
};
