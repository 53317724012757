import { Entry, getEntryFromData } from '~/data/entry';
import { getMediaFromData } from '~/data/media';

//
// #data object definitions
//
export class Employee extends Entry {
  constructor({ firstname, lastname, position, curriculum, mail, photo, links, ...entry }) {
    super(entry);
    this.firstname = firstname;
    this.lastname = lastname;
    this.position = position;
    this.curriculum = curriculum;
    this.mail = mail;
    this.photo = photo;
    this.links = links;
  }
}

//
// #data object factories
//
export function getEmployeeFromData(apiData) {
  const data = apiData?.attributes;
  if (!data) { return null }

  const entry = getEntryFromData(apiData);
  const photo = getMediaFromData(data.photo);
  const { firstname, lastname, position, curriculum, email: mail, links} = data;
  
  return new Employee({ firstname, lastname, position, curriculum, mail, photo, links, ...entry });
}

export function getEmployeeCollectionFromData(apiData) {
  return apiData.map(data => getEmployeeFromData(data));
}