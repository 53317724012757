<template>
  <div class="header-iso">
    <UiTitleIso
      class="header-iso__title"
      :content="title"
      :reverse="true"
      :underline="false"
    />

    <p class="header-iso__subtitle typo-header-italic app-iso-margin">
      {{ subtitle }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
  },
};
</script>

<style lang="stylus" scoped>
.header-iso__subtitle {
  +size(XL) {
    min-height 100px
    width 65%
  }
  +size(XXL) {
    width 75%
  }
}
</style>