import { default as _91_46_46_46path_93w67p1e6PF2Meta } from "/opt/atlassian/pipelines/agent/build/pages/[...path].vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as _91item_93sMiE4PoFzDMeta } from "/opt/atlassian/pipelines/agent/build/pages/points-de-vue/[item].vue?macro=true";
import { default as indexAsPrwqWluWMeta } from "/opt/atlassian/pipelines/agent/build/pages/points-de-vue/index.vue?macro=true";
import { default as points_45de_45vueMzoLjim3g2Meta } from "/opt/atlassian/pipelines/agent/build/pages/points-de-vue.vue?macro=true";
import { default as _91item_93ZA58oOglHoMeta } from "/opt/atlassian/pipelines/agent/build/pages/realisations/[item].vue?macro=true";
import { default as indexWx8XUUVg3MMeta } from "/opt/atlassian/pipelines/agent/build/pages/realisations/index.vue?macro=true";
import { default as realisations4r7LD3UaR8Meta } from "/opt/atlassian/pipelines/agent/build/pages/realisations.vue?macro=true";
import { default as _91item_93LFqSQPz04HMeta } from "/opt/atlassian/pipelines/agent/build/pages/savoir-faire/[item].vue?macro=true";
import { default as indexxpHVyDjcIGMeta } from "/opt/atlassian/pipelines/agent/build/pages/savoir-faire/index.vue?macro=true";
import { default as savoir_45fairejnSzosHMjYMeta } from "/opt/atlassian/pipelines/agent/build/pages/savoir-faire.vue?macro=true";
export default [
  {
    name: _91_46_46_46path_93w67p1e6PF2Meta?.name ?? "path",
    path: _91_46_46_46path_93w67p1e6PF2Meta?.path ?? "/:path(.*)*",
    meta: _91_46_46_46path_93w67p1e6PF2Meta || {},
    alias: _91_46_46_46path_93w67p1e6PF2Meta?.alias || [],
    redirect: _91_46_46_46path_93w67p1e6PF2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[...path].vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    path: points_45de_45vueMzoLjim3g2Meta?.path ?? "/points-de-vue",
    children: [
  {
    name: _91item_93sMiE4PoFzDMeta?.name ?? "points-de-vue-item",
    path: _91item_93sMiE4PoFzDMeta?.path ?? ":item()",
    meta: _91item_93sMiE4PoFzDMeta || {},
    alias: _91item_93sMiE4PoFzDMeta?.alias || [],
    redirect: _91item_93sMiE4PoFzDMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/points-de-vue/[item].vue").then(m => m.default || m)
  },
  {
    name: indexAsPrwqWluWMeta?.name ?? "points-de-vue",
    path: indexAsPrwqWluWMeta?.path ?? "",
    meta: indexAsPrwqWluWMeta || {},
    alias: indexAsPrwqWluWMeta?.alias || [],
    redirect: indexAsPrwqWluWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/points-de-vue/index.vue").then(m => m.default || m)
  }
],
    name: points_45de_45vueMzoLjim3g2Meta?.name ?? undefined,
    meta: points_45de_45vueMzoLjim3g2Meta || {},
    alias: points_45de_45vueMzoLjim3g2Meta?.alias || [],
    redirect: points_45de_45vueMzoLjim3g2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/points-de-vue.vue").then(m => m.default || m)
  },
  {
    path: realisations4r7LD3UaR8Meta?.path ?? "/realisations",
    children: [
  {
    name: _91item_93ZA58oOglHoMeta?.name ?? "realisations-item",
    path: _91item_93ZA58oOglHoMeta?.path ?? ":item()",
    meta: _91item_93ZA58oOglHoMeta || {},
    alias: _91item_93ZA58oOglHoMeta?.alias || [],
    redirect: _91item_93ZA58oOglHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/realisations/[item].vue").then(m => m.default || m)
  },
  {
    name: indexWx8XUUVg3MMeta?.name ?? "realisations",
    path: indexWx8XUUVg3MMeta?.path ?? "",
    meta: indexWx8XUUVg3MMeta || {},
    alias: indexWx8XUUVg3MMeta?.alias || [],
    redirect: indexWx8XUUVg3MMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/realisations/index.vue").then(m => m.default || m)
  }
],
    name: realisations4r7LD3UaR8Meta?.name ?? undefined,
    meta: realisations4r7LD3UaR8Meta || {},
    alias: realisations4r7LD3UaR8Meta?.alias || [],
    redirect: realisations4r7LD3UaR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/realisations.vue").then(m => m.default || m)
  },
  {
    path: savoir_45fairejnSzosHMjYMeta?.path ?? "/savoir-faire",
    children: [
  {
    name: _91item_93LFqSQPz04HMeta?.name ?? "savoir-faire-item",
    path: _91item_93LFqSQPz04HMeta?.path ?? ":item()",
    meta: _91item_93LFqSQPz04HMeta || {},
    alias: _91item_93LFqSQPz04HMeta?.alias || [],
    redirect: _91item_93LFqSQPz04HMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/savoir-faire/[item].vue").then(m => m.default || m)
  },
  {
    name: indexxpHVyDjcIGMeta?.name ?? "savoir-faire",
    path: indexxpHVyDjcIGMeta?.path ?? "",
    meta: indexxpHVyDjcIGMeta || {},
    alias: indexxpHVyDjcIGMeta?.alias || [],
    redirect: indexxpHVyDjcIGMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/savoir-faire/index.vue").then(m => m.default || m)
  }
],
    name: savoir_45fairejnSzosHMjYMeta?.name ?? undefined,
    meta: savoir_45fairejnSzosHMjYMeta || {},
    alias: savoir_45fairejnSzosHMjYMeta?.alias || [],
    redirect: savoir_45fairejnSzosHMjYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/savoir-faire.vue").then(m => m.default || m)
  }
]