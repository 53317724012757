<template>
  <div class="detail-employee">
    <div class="detail-employee__head theme-matr">
      <UiMediaImage
        :image="photo"
        fit
        width="360"
        height="360"
        class="detail-employee__image"
      />

      <p class="detail-employee__title typo-h3">
        {{ name }}
      </p>

      <p class="detail-employee__text">
        {{ position }}
      </p>
    </div>

    <div class="detail-employee__body">
      {{ curriculum }}
      <br><br>
      <a class="detail-employee__links" :href="`mailto:${mail}`"><b>{{ mail }}</b></a>
      <a
        v-for="(item, index) in links"
        :key="`${name}-link-${index}`"
        :href="item.url"
        :target="item.target"
        class="detail-employee__links"
      ><b>{{ item.label }}</b></a>
    </div>
  </div>
</template>

<script>
import { Media } from '~/data/media.js';

export default {
  props: {
    photo: { type: Media, default: null },
    name: { type: String, default: null },
    position: { type: String, default: null },
    curriculum: { type: String, default: null },
    mail: { type: String, default: null },
    links: { type: Array, default: () => [] },
  },
};
</script>

<style lang="stylus" scoped>

.detail-employee__head {
  background-color var(--theme-color, white)
  padding 30px
  box-sizing border-box
}

.detail-employee__text,
.detail-employee__title,
.detail-employee__image {
  width 258px
  margin 0 auto
}

.detail-employee__image {
  display block

  mask-image url('/medias/shape-flat-button.svg')
  mask-size 90%
  mask-repeat no-repeat
  mask-position center
  filter grayscale(1)

  margin-bottom 30px
}

.detail-employee__body {
  background-color white
  padding 50px 30px
}

.detail-employee__links {
  display block
}
</style>
