<template>
  <div class="expandable">
    <div :class="['expandable__head', { 'expandable__head--open': open }]" @click="open = !open">
      <p class="expandable__title">
        {{ title }}
      </p>
      <UiButtonFlat v-show="!open" type="more" small />
      <UiButtonFlat v-show="open" type="minus" small />
    </div>

    <Transition>
      <BlocksTextsRichtext
        v-show="open"
        :content="content"
        class="expandable__content"
      />
    </Transition>
  </div>
</template>

<script>
export default {
    props: {
        content: { type: String, required: true },
        title: { type: String, required: true },
  },

  data() {
    return ({
      open: false,
    });
  },
};
</script>

<style lang="stylus" scoped>
.expandable__head {
  display flex
  justify-content space-between

  border-top 1px solid $cl-separator
  cursor pointer
}

.expandable__title {
  margin 25px 0
  font-weight bold
  transition color 0.25s

  .expandable__head--open & {
    color var(--theme-color, black)
  }
}

.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-leave-active {
  transition: opacity 0.1 ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
