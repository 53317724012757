<template>
  <LayoutCollection :row-pattern="[4]" v-bind="$props" class="theme-rapp">
    <template #item="{ item }">
      <UiCardReport
        :button-label="item.published"
        :description="item.description"
        :href="item.url"
        :image="item.cover"
        :name="item.name"
      />
    </template>
  </LayoutCollection>
</template>

<style lang="stylus" scoped>
$collection-layout-breakpoint := L

:deep(.grid-layout__items) {
  border 0.5px solid $cl-separator
}

:deep(.grid-layout__item) {
  border 0.5px solid $cl-separator
  box-sizing border-box
  width 100%

  +size($collection-layout-breakpoint) {
    width 25%
    background-color white
  }

  &:nth-child(odd) {
    background-color var(--theme-color, white)
    +size($collection-layout-breakpoint) {
      background-color white
    }
  }
}

:deep(.report-card) {
  box-sizing border-box
  padding 50px 60px
  width 100%
  height 100%

  +size($collection-layout-breakpoint) {
    padding 75px 50px
  }
}
</style>
