import { dateModifier } from '~/data/utils';
import { CollectionEntry, getCollectionEntryFromData } from '~/data/entry';
import { getClientFromData } from '~/data/client';
import { getMediaFromData } from '~/data/media';
import { getPartnerFromData } from '~/data/partner';

//
// #data object definitions
//
export class Project extends CollectionEntry {
  constructor({ name, startDate, endDate, cover, client, partners, ...collectionEntry }) {
    super(collectionEntry);
    this.name = name;
    this.startDate = startDate;
    this.endDate = endDate;
    this.cover = cover;
    this.client = client;
    this.partners = partners;
  }
}
//
// #data object factories
//
export function getProjectFromData(apiData) {
  const data = apiData?.attributes;
  if (!data) { return null }

  const collectionEntry = getCollectionEntryFromData(apiData, getRelatedCardFromData);
  const { name } = data;
  const startDate = data.startDate ? dateModifier(data.startDate) : null;
  const endDate = data.endDate ? dateModifier(data.endDate) : null;
  const cover = getMediaFromData(data.cover);
  const client = getClientFromData(data.client?.data);
  const partners = data.partners?.data?.map(partnerData => getPartnerFromData(partnerData));

  const params = { name, startDate, endDate, cover, client, partners, ...collectionEntry };
  return new Project(params);
}

export function getProjectCollectionFromData(apiData) {
  return apiData.map(data => getProjectFromData(data));
}

export function getRelatedCardFromData(apiData) {
  const project = getProjectFromData(apiData);
  return {
    cover: project.cover,
    name: project.name,
    label: project.client?.name,
    href: project.url,
    target: '_self',
  };
}