<template>
  <div class="small-pictures">
    <template v-for="(iDuo1, iDuo) in duoCount" :key="`duo-${iDuo}`">
      <div class="small-pictures__items">
        <template
          v-for="(index1, index) in 2"
          :key="`media-${index}-duo-${iDuo}`"
        >
          <UiMediaImage
            v-if="items[(iDuo * 2) + index]"
            :image="items[(iDuo * 2) + index].picture"
            width="505"
            ratio="1"
            masked
            fit
            class="small-pictures__item"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { getMediaFromData } from '~/data/media';

export default {
  props: {
    content: { type: Object, default: null },
  },

  computed: {
    items() {
      return this.content.map(i => ({ picture: getMediaFromData(i.picture) }));
    },

    duoCount() {
      return Math.round(this.items.length / 2);
    },
  },
};
</script>

<style lang="stylus" scoped>
.small-pictures {
}

.small-pictures__items {
  display flex
  justify-content space-between
  margin-top 50px
  &:first-child {
    margin-top 0
  }
}

.small-pictures__item {
  aspect-ratio 1/1
  width 40%
  flex-shrink 0

  +size(S) {
    max-width 505px
  }
}
</style>
