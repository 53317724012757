<template>
  <LayoutCard
    :href="href"
    :class="[
      'report-card',
      { 'report-card--link': !!href },
    ]"
    target="_blank"
  >
    <template #head>
      <UiMediaImage
        :image="image"
        :fit="true"
        :ratio="3/4"
        :quality="40"
        class="report-card__image"
        width="360"
      />
      <UiButtonFlat type="download-flat" class="report-card__arrow" />
    </template>

    <template v-if="buttonLabel" #overlay>
      <UiTag :label="buttonLabel" />
    </template>

    <template #body>
      <p class="report-card__title typo-h3">
        {{ name }}
      </p>
      <p class="report-card__text">
        {{ description }}
      </p>
    </template>
  </LayoutCard>
</template>

<script>
import { Media } from '~/data/media.js';
export default {
  props: {
    buttonLabel: { type: String, default: null },
    description: { type: String, default: null },
    href: { type: String, default: null },
    image: { type: Media, default: null },
    name: { type: String, default: null },
  },
};
</script>

<style lang="stylus" scoped>
card-color-animation('.report-card', '.report-card__title, .report-card__text')

.report-card__image {
  aspect-ratio 3/4
}

.report-card__arrow {
  position absolute
  top 20px
  right 20px

  --theme-color-text black
  --theme-color white

  .report-card--link:hover & {
    --theme-color-text white
    --theme-color black
  }
}

:deep(.card-layout__overlay) {
  bottom 20px
  left 20px
}
</style>
