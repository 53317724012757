<template>
  <div class="header-hero">
    <BlocksTextsRichtext
      v-if="!!textSupHeader"
      :content="textSupHeader"
      class="header-hero__head app-gutter"
    />

    <div class="header-hero__wrapper">
      <div class="header-hero__cover">
        <UiMediaImage
          class="header-hero__cover-pic"
          :image="cover"
          :masked="true"
          :fit="true"
          width="1800"
          ratio="2.28"
        />
      </div>

      <div class="header-hero__text app-gutter-mobile-only">
        <h1 class="header-hero__title">
          {{ title }}
        </h1>

        <BlocksTextsRichtext :content="textIntro" />
      </div>

      <div v-if="textDetail" class="header-hero__details app-gutter-mobile-only">
        <template v-for="(line, index) in textDetail" :key="index">
          <p class="header-hero__detail">
            <b class="header-hero__detail-title typo-h3">{{ line.label }}</b>
            <span class="typo-default">{{ line.value }}</span>
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Media, getMediaFromData } from '~/data/media.js';
import { RichText, getRichTextsFromData } from '~/data/richtext.js';

export default {
  props: {
    hero: { type: [Object, Media], default: null },
    intro: { type: [Object, String], default: null },
    supheader: { type: [Object, String], default: null },
    title: { type: String, default: '' },
    detail: { type: Object, default: null },
  },

  computed: {
    cover() {
      return this.hero instanceof Media ? this.hero : getMediaFromData(this.hero);
    },

    textIntro() {
      if (typeof this.intro === 'string') return this.intro;

      const richText = this.intro?.data instanceof RichText ? this.intro.data : getRichTextsFromData(this.intro?.data);
      return richText ? richText.content : '';
    },

    textSupHeader() {
      if (typeof this.supheader === 'string') return this.supheader;

      const richText = this.supheader?.data instanceof RichText ? this.supheader.data : getRichTextsFromData(this.supheader?.data);
      return richText ? richText.content : '';
    },

    textDetail() {
      if (!this.detail) return;
      return Object.entries(this.detail).filter(([,value]) => value != undefined).map(([label, value]) => ({ label, value }));
    },
  },
};
</script>

<style lang="stylus" scoped>
$text-offset-y-mobile = -45px
$text-offset-y-desktop = -75px
$text-maxwidth = 1000px
$text-maxwidth-computed = s('min(1000px, calc(100% - 45px))')
$aspectratio = 2.28

.header-hero__head {
  margin-top 50px
  margin-bottom 50px
  +size(L) {
    margin-top 73px
  }
}
.header-hero__wrapper {
  position relative
}

.header-hero__cover {
  box-sizing border-box
  padding-left 60px
  +size(L) {
    padding-left 126px
  }
}

.header-hero__cover-pic {
  aspect-ratio 1.68
  +size(M) {
    aspect-ratio $aspectratio
  }
}

.header-hero__title {
  margin-top 0
}

.header-hero__text {
  text-align left

  max-width $text-maxwidth
  max-width $text-maxwidth-computed

  box-sizing border-box
  padding-top 50px

  transform translate(-1px, $text-offset-y-mobile)
  background s('linear-gradient(180deg, white %s, %s %s, %s)', -($text-offset-y-mobile), alpha(white, 0), -($text-offset-y-mobile) + 1, alpha(white, 0))

  +size(L) {
    background s('linear-gradient(180deg, white %s, %s %s, %s)', -($text-offset-y-desktop), alpha(white, 0), -($text-offset-y-desktop) + 1, alpha(white, 0))
    transform translate(-1px, $text-offset-y-desktop)
    padding-left 126px
    padding-right @padding-left
  }
}
.header-hero__details {
  margin-top $text-offset-y-mobile

  +size(L) {
    margin-top ($text-offset-y-desktop + 50px)
    padding-left 126px
  }

  +size(1440px) {
    position absolute
    top 0

    margin-left $text-maxwidth
    margin-left $text-maxwidth-computed

    padding-top (1 / $aspectratio) * 100%
    padding-left 0
  }
}

.header-hero__detail {
  margin 0 0 15px 0
  +size(L) {
    margin 0 0 24px 0
  }
}

.header-hero__detail-title {
  margin-right 1em
  +size(L) {
    display block
  }
}
</style>
