<template>
  <component
    :is="tagName"
    :class="[
      'title-arrow',
      'typo-h2',
      { 'title-arrow--hidden': !hasArrow },
    ]"
  >
    {{ content }}
  </component>
</template>

<script>
import UiTitle from '~/components/ui/Title';

export default {
  mixins: [UiTitle],
  props: {
    hasArrow: { type: Boolean, default: true },
  },
};
</script>

<style lang="stylus" scoped>
.title-arrow {
  vertical-align middle

  &:after {
    content ''
    display inline-block

    width 13px
    height 20px

    vertical-align middle
    margin-left 15px
    margin-bottom 5px

    background-image embedurl('~/assets/images/title-arrow.svg', 'utf8')
    background-size cover
    background-repeat no-repeat
  }

  &.title-arrow--hidden::after {
    display none
  }
}
</style>
