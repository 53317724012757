import { Entry, getEntryFromData } from '~/data/entry';
import { getRelatedCardFromData } from '~/data/project';

//
// #data object definitions
//
export class Service extends Entry {
  constructor({ name, subtitle, description, category, projects, ...entry }) {
    super(entry);
    this.name = name;
    this.subtitle = subtitle;
    this.description = description;
    this.category = category;
    this.projects = projects;
  }
}

export class ServiceCategory extends Entry {
  constructor({ name, services, ...entry }) {
    super(entry);
    this.name = name;
    this.services = services;
  }
}
//
// #data object factories
//
export function getServiceFromData(apiData) {
  const data = apiData?.attributes;
  if (!data) { return null }

  const entry = getEntryFromData(apiData);
  const { name, subtitle, description } = data;
  const category = getServiceCategoryFromData(data?.['services_category']?.data);
  const projects = data?.projects?.data?.map(project => getRelatedCardFromData(project));

  return new Service({ name, subtitle, description, category, projects, ...entry });
}

export function getServiceCollectionFromData(apiData) {
  return apiData.map(data => getServiceFromData(data));
}

export function getServiceCategoryFromData(apiData) {
  const data = apiData?.attributes;
  if (!data) { return null }

  const entry = getEntryFromData(apiData);

  const { name } = data;
  const services = data?.services?.data?.map(service => getServiceFromData(service));

  return new ServiceCategory({ name, services, ...entry });
}

export function getServiceCollectionFromCategoryListData(apiData) {
  const data = apiData;
  if (!data?.length) { return null }

  const services = [];
  data.forEach(category => services.push(...getServiceCategoryFromData(category).services));
  return services;
}
