<template>
  <component :is="tagName">
    {{ content }}
  </component>
</template>

<script>
export default {
  props: {
    level: { type: [Number, String], default: 1 },
    content: { type: String, default: '' },
  },

  computed: {
    tagName() {
      const level = Number(this.level) || 1;
      if (level < 10) return `h${level}`;
      return 'h1';
    },
  },
};
</script>
