import { Entry, getEntryFromData } from '~/data/entry';
import { getProjectFromData } from '~/data/project';

//
// #data object definitions
//
export class Featured extends Entry {
  constructor({ title, description, buttonLabel, project, ...entry }) {
    super(entry);
    this.title = title;
    this.description = description;
    this.buttonLabel = buttonLabel;
    this.project = project;
  }
}
//
// #data object factories
//
export function getFeaturedFromData(apiData) {
  const data = apiData?.attributes || apiData;
  if (!data) { return null }

  const entry = getEntryFromData(apiData);
  const { title, description, cta: buttonLabel } = data;
  const project = getProjectFromData(data.project?.data);

  return new Featured({ title, description, buttonLabel, project, ...entry });
}