<template>
  <UiButtonBlock
    :label="label"
    :to="$mediaUrl(media.url)"
    :picto="pictoType"
    :component="componentName"
    target="_blank"
  >
    <div v-show="!shouldDisplayPlayer" class="file__detail">
      <span>{{ media.label }}</span>
      <span>{{ detail }}</span>
    </div>

    <UiPlayerAudio
      v-if="isPlayer"
      v-show="shouldDisplayPlayer"
      ref="player"
      class="file__player"
      :path="$mediaUrl(media.url)"
      @start="shouldDisplayPlayer = true"
      @end="shouldDisplayPlayer = false"
    />
  </UiButtonBlock>
</template>

<script>
import { getMediaFromData } from '~/data/media';

export default {
  props: {
    label: { type: String, default: null },
    file: { type: Object, default: null },
  },

  data() {
    return ({
      shouldDisplayPlayer: false,
    });
  },

  computed: {
    media() {
      return getMediaFromData(this.file, this.label);
    },

    pictoType() {
      switch (this.media.ext) {
        case '.mp3':
          return 'play';
        default:
          return 'download';
      }
    },

    detail() {
      if (!this.media.ext && !this.media.size) return null;

      const out = `(${this.media.ext ? this.media.ext + ' ' : ''}`;
      return `${out}${this.media.size ? this.media.size : ''})`;
    },

    isPlayer() { return this.pictoType === 'play' },

    componentName() {
      if (this.isPlayer) { return 'button' }
      return resolveComponent('NuxtLink');
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.$el.firstElementChild.addEventListener('click', this.play);
    });
  },

  breforeDestroy() {
    if (this.$el && this.$el.firstElementChild) {
      this.$el.firstElementChild.removeEventListener('click', this.play);
    }
  },

  methods: {
    play() {
      if (this.isPlayer && this.$refs.player) {
        this.$refs.player.toggle();
      }
    },
  },
};
</script>

<style lang="stylus" scoped>

.file__detail,
.file__player {
  padding 20px 25px 20px 75px
}

.file__detail {
  display flex
  flex-direction column
  box-sizing border-box
  align-items flex-start

  +size(S) {
    justify-content space-between
    flex-direction row
  }
}
</style>
