<template>
  <div class="header-edito">
    <BlocksHeadersHeaderCentered v-bind="$props" />

    <div class="header-edito__offset">
      <div class="header-edito__filters" />
      <div class="header-edito__card" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
  },
};
</script>

<style lang="stylus" scoped>
$collection-layout-breakpoint := L

.header-edito {
  +size($collection-layout-breakpoint) {
    display flex
  }
}

.header-edito__filters {
  text-align right
}

.header-edito__offset {
  +size($collection-layout-breakpoint) {
    flex-grow 1
    margin-left 45px
  }
}

:deep(.filters-combo) {
  text-align right
  margin-top 25px
  margin-bottom @margin-top
  margin-right 0
}

:deep(.header-centered) {
  +size($collection-layout-breakpoint) {
    max-width 50%
    text-align left
  }
}
</style>