<template>
  <NuxtImg
    :class="[
      'media-image',
      { 'media-image--masked': masked },
      { 'media-image--fit': fit || cover },
      { 'media-image--contain': contain },
    ]"
    :src="$mediaUrl(image.url)"
    :width="w"
    :height="h"
    :alt="image.alt"
    format="webp"
    :quality="quality"
    v-bind="options"
  />
</template>

<script>
import { Media } from '~/data/media.js';

export default {
  props: {
    image: { type: Media, default: null },
    masked: { type: Boolean, default: false },
    fit: { type: Boolean, default: false },
    cover: { type: Boolean, default: false },
    contain: { type: Boolean, default: false },
    width: { type: [ String, Number ], default: 'auto' },
    height: { type: [ String, Number ], default: 'auto' },
    ratio: { type: [ String, Number ], default: 'auto' },
    quality: {type: Number, default: 50},
    options: { type: Object, default: null },
  },

  computed: {
    w() {
      if (!isNaN(this.width)) { return this.width }
      if (this.width === 'auto' && !isNaN(this.height) && !isNaN(this.ratio)) {
        return Math.round(this.height * this.ratio);
      }
      return this.image.width;
    },

    h() {
      if (!isNaN(this.height)) { return this.height }
      if (this.height === 'auto' && !isNaN(this.width) && !isNaN(this.ratio)) {
        return Math.round(this.width / this.ratio);
      }
      return this.image.height;
    },
  },
};
</script>

<style lang="stylus" scoped>
.media-image--masked {
  $mask-size = 15%
  clip-path polygon(0% $mask-size, $mask-size 0%, 100% 0%, 100% 100%, 0% 100%)
}

.media-image--fit {
  width 100%
  height 100%
  object-fit cover
  object-position center
}

.media-image--contain {
  width 100%
  height 100%
  object-fit contain
  object-position center
}
</style>
