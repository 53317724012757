<template>
  <div class="card-layout">
    <div class="card-layout__head">
      <div class="card-layout__image">
        <slot name="head">
          <img :src="src">
        </slot>
      </div>

      <div class="card-layout__overlay">
        <slot name="overlay" />
      </div>
    </div>

    <NuxtLink
      v-if="href"
      :to="href"
      :target="target"
      class="card-layout__link"
    >
      <span class="card-layout__href-label">{{ href }}</span>
    </NuxtLink>

    <div class="card-layout__body">
      <slot name="body">
        <!-- eslint-disable vue/no-lone-template -->
        <!-- eslint-disable vue/no-v-html -->
        <template v-html="content" />
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: { type: String, default: null },
    href: { type: String, default: null },
    target: { type: String, default: null },
    src: { type: String, default: null },
  },
};
</script>

<style lang="stylus" scoped>
.card-layout {
  position relative
}

.card-layout__head {
  position relative
}

.card-layout__overlay {
  position absolute
  z-index 1
}

.card-layout__link {
  position absolute
  z-index 0
  top 0
  left 0

  width 100%
  height 100%

  cursor pointer
}

.card-layout__image {
  line-height 0
}

.card-layout__href-label {
  display block
  overflow hidden
  height 0
  opacity 0
}
</style>
