<template>
  <div class="panel" @mouseup="close">
    <button class="panel__close" @click="close">
      <UiPicto class="panel__picto" type="more" />
    </button>

    <div class="panel__content" @mouseup.capture.prevent.stop="">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  emits: ['close'],

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="stylus" scoped>
.panel {
  position relative
  background-color white
  width 100%

  &:before {
    content ''

    position fixed
    top 0
    right 0
    bottom 0
    left 0
    z-index -1

    width 100%
    height 100%
    background rgba(0, 0, 0, 0.2)
  }
}

.panel__close {
  @extends $reset-button
  position absolute
  top 0
  right 0
  z-index 1

  width 60px
  height @width

  background-color black
  color white
  cursor pointer

  transition border-radius 0.25s

  .panel__picto {
    clip-path circle(50%)
    transition clip-path 0.5s
  }

  &:hover {
    border-bottom-left-radius 7px

    .panel__picto {
      clip-path circle(25%)
    }
  }
}

.panel__picto {
  transform rotate(45deg)
}

.panel__content {
  max-height 100%
  overflow-y auto
}
</style>
