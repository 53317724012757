<template>
  <div>
    <Carousel
      v-model="currentSlide"
      v-bind="options"
      :wrap-around="slides.length > 1"
      :mouse-drag="slides.length > 1"
      :touch-drag="slides.length > 1"
      class="gallery-layout"
    >
      <Slide v-for="(slideItem, index) in slides" :key="`slide-${index}`" class="gallery-layout__item">
        <slot
          name="slide"
          :slide="slideItem"
          :slide-index="index"
          :is-active="index === currentSlide"
        >
          <span>Here the slide #{{ index }} content</span>
        </slot>
      </Slide>
    </Carousel>

    <div v-if="slides.length > 1" class="gallery-layout__navigation">
      <div @click="currentSlide = currentSlide - 1">
        <slot name="previous" />
      </div>

      <div @click="currentSlide = currentSlide + 1">
        <slot name="next" />
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  components: { Carousel, Slide },

  props: {
    slides: { type: Array, default: () => [] },
    options: { type: Object, default: () => ({}) },
  },

  data() {
    return ({
      currentSlide: 0,
    });
  },
};
</script>
