<template>
  <div v-dompurify-html="markdownToHtml" class="richtext" />
</template>

<script>
import { Marked } from 'marked';
import { mangle } from 'marked-mangle';

const marked = new Marked({ headerIds: false });
marked.use(mangle());

export default {
  props: {
    content: { type: String, required: true },
  },

  computed: {
    markdownToHtml() {
      const markdown = this.content;
      return marked.parse(markdown);
    },
  },
};
</script>

<style lang="stylus" scoped>
.richtext {
  :deep(ul),
  :deep(ol) {
    margin-top 0
    margin-bottom 25px
  }
  :deep(p) {
    margin-top 0
    margin-bottom 30px
    + ol,
    + ul {
      margin-top -25px
    }
  }
}
</style>
