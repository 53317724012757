<template>
  <component
    :is="componentName"
    v-if="toggle"
    :class="[
      'tag',
      { 'tag--checked': modelValue },
      { 'tag--toggle': toggle },
      { 'tag--link': !!href },
    ]"
    :href="href"
    @click="toggleCheck"
  >
    {{ label }}
  </component>
</template>

<script>
export default {
  props: {
    href: { type: String, default: null },
    label: { type: String, default: '' },
    toggle: { type: Boolean, default: true },
    modelValue: { type: Boolean, default: false },
  },

  emits: ['update:modelValue'],

  computed: {
    componentName() {
        if (!this.href) { return 'button' }
        return resolveComponent('NuxtLink');
      },
  },

  methods: {
    toggleCheck() {
      this.$emit('update:modelValue', !this.modelValue);
    },
  },
};
</script>

<style lang="stylus" scoped>
.tag {
  @extends $reset-button, .typo-small
  box-sizing border-box
  padding 0 10px
  height 29px

  line-height @height
  white-space nowrap
  text-decoration none

  background-color white
  border 1px solid black

  transition border-radius 0.25s, background-color 0.15s
}

.tag--toggle {
  cursor pointer
}
.tag:hover {
  background-color var(--theme-color, black)
  border-top-left-radius 7px
}
.tag--checked {
  color white
  background-color black
  border-top-left-radius 7px
  &:hover {
    color white
    background-color black
    border-top-left-radius 0px
  }
}
</style>
