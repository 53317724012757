<template>
  <UiButtonBlock
    :label="label"
    :target="target"
    :to="url"
    picto="link"
  />
</template>

<script>
export default {
  props: {
    label: { type: String, default: null },
    target: { type: String, default: null },
    url: { type: String, default: null },
  },
};
</script>
